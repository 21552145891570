import Cookies from "js-cookie";
import { Reducer } from "redux";
import { AppThunkAction } from ".";
import { parseJwt, requestWithAxios } from "../helpers/func";
import setAuthToken from "../helpers/setAuthToken";
import { CHANGE_PASSWORD_END, CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_START, CLEAR_JWT, CLEAR_PARENT, GET_NOTIFICATION, GET_PARENT_END, GET_PARENT_FAIL, GET_PARENT_START, GET_TRANSACTION, Login_fail, Login_Success, LOGOUT, Request_Login, UPDATE_PARENT_END, UPDATE_PARENT_FAIL, UPDATE_PARENT_START, FETCH_PARENT_DICTIONARY } from "./ActionTypes";
import * as Initial from "./initialStatus";

import { url } from './initialStatus'
/**
 * status
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error
 */

interface AddAssessment {
  type: string;
  msg: string;
  Questions: [] | undefined;
}

type KnownAction = AddAssessment;

export const actionCreators = {


  UpdateParent:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: UPDATE_PARENT_START })
        const res = await requestWithAxios(url + "guardian/updateguardian.php", data);

        try {

          dispatch({ type: UPDATE_PARENT_END, MSG: res.data.msg, guardian: res.data.data })
        } catch (error) {
          dispatch({ type: UPDATE_PARENT_FAIL, error: "somthing went wrong" })
        }
      },
  ClearParent:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: CLEAR_PARENT })
      },


  ChangePassword:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: CHANGE_PASSWORD_START })
        const res = await requestWithAxios(url + "guardian/updatepassword.php", data);

        try {
          dispatch({ type: CHANGE_PASSWORD_END, MSG: res.data.msg })
        } catch (error) {
          dispatch({ type: CHANGE_PASSWORD_FAIL, error: res.data.msg })
        }
      },




  GetParent:
    (id: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: GET_PARENT_START })
        const res = await requestWithAxios(url + "guardian/getguardian.php", { id: id });
        try {
          dispatch({ type: GET_PARENT_END, res: res.data.data })

        } catch (error) {

          dispatch({ type: GET_PARENT_FAIL, error: "somthing went wrong" })
        }


      },
  UploadFile:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: GET_PARENT_START })
        const res = await requestWithAxios(url + "guardian/upload.php", data);


      },
  GenerateCode:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: CHANGE_PASSWORD_START })
        const res = await requestWithAxios(url + "guardian/generatecode.php", data);

        try {
          dispatch({ type: CHANGE_PASSWORD_END, MSG: res.data.msg })
        } catch (error: any) {
          dispatch({ type: CHANGE_PASSWORD_FAIL, error: "Your Email is not Exists in our Database.<br> Please Make sure of it or, Contact us: <br> Phone:<a href='tel:+20 110 208 9000'>+20 110 208 9000</a><br>Email:<a href='mailto:ivyparent@ivyis.org'>IVYParent@ivyis.org</a>" })
        }
      },
  CheckCode:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: CHANGE_PASSWORD_START })
        const res = await requestWithAxios(url + "guardian/checkcode.php", data);


        try {
          dispatch({ type: CHANGE_PASSWORD_END, MSG: res.data.msg })
        } catch (error) {

          dispatch({ type: CHANGE_PASSWORD_FAIL, error: "Verification code is not correct " })
        }
      },
  NewPassword:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: CHANGE_PASSWORD_START })
        const res = await requestWithAxios(url + "guardian/newpassword.php", data);


        try {
          dispatch({ type: CHANGE_PASSWORD_END, MSG: res.data.msg })
        } catch (error) {

          dispatch({ type: CHANGE_PASSWORD_FAIL, error: res.data.msg })
        }
      },
  Logout:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: LOGOUT })
        Cookies.set('guardiantoken', "");
        Cookies.set('guardian', "");
        Cookies.set('guardiantranstoken', "");
        Cookies.remove("guardiantoken");
        Cookies.remove("guardian");
        Cookies.remove("guardiantranstoken");
        dispatch({ type: CLEAR_JWT })


      },
  ClearJwt:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        dispatch({ type: CLEAR_JWT })


      },
  SaveToken:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(url + "auth/savetoken.php", data);


      },
  ReadNotification:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const res = await requestWithAxios(url + "users/chatlistusers/readnoti.php", data);


      },
  Login:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        dispatch({ type: Request_Login })

        const res = await requestWithAxios(url + "auth/login.php", data);
        if (res.data.data === "error") {

          dispatch({ type: Login_fail, error: res.data.msg })

        } else {

          const data = parseJwt(res.data.data.guardian_info.token);
          const expDate: any = new Date(new Date(data.exp * 1000));
          Cookies.set("guardiantoken", res.data.data.guardian_info.token, { sameSite: 'none', secure: true, expires: 3 });
          Cookies.set("guardiantranstoken", res.data.data.guardian_info.transToken, { sameSite: 'none', secure: true, expires: 3 });
          Cookies.set("guardian", JSON.stringify(res.data.data.guardian_info), { sameSite: 'none', secure: true, expires: 3 });

          localStorage.setItem("parent_dict", JSON.stringify(res.data.data.parent_dict.data))

          dispatch({
            type: Login_Success, expDate: expDate, jwt: res.data.data.guardian_info.token, transjwt: res.data.data.guardian_info.transToken,
            guardian: res.data.data.guardian_info.guardian,
            schoologyUserName: res.data.data.guardian_info.schoologyUserName,
            schoologyPass: res.data.data.guardian_info.schoologyPass
          })
          setAuthToken(res.data.data.token);
        }

      },
  LoginIframe:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
        const token = Cookies.get("guardiantoken");
        const transToken = Cookies.get("guardiantranstoken");
        const Gdata = JSON.parse(Cookies.get("guardian") ?? "");
        if (token === undefined || token === null) {

        } else {
          const data = parseJwt(token);
         
          const expDate: any = new Date(new Date(data.exp * 1000));
          dispatch({
            type: Login_Success, expDate: expDate, jwt: token, transjwt: transToken,
            guardian: Gdata.guardian,
            schoologyUserName: Gdata.schoologyUserName,
            schoologyPass: Gdata.schoologyPass

          })
          setAuthToken(token);
        }

      },
  FetchDictionary:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        const res = await requestWithAxios(url + "translation/getdictionary.php", {});
        // window.location.reload();
        try {
         
          if (!localStorage.getItem("parent_dict")) {
            // window.location.reload();
          }
          localStorage.setItem("parent_dict", JSON.stringify(res?.data?.data?.data))

          dispatch({ type: FETCH_PARENT_DICTIONARY, dictionary: res.data.data.data })
        } catch (error) {
          dispatch({ type: UPDATE_PARENT_FAIL, error: "somthing went wrong" })
        }
      },

  GetNotifications:
    (data: any, name: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        const res = await requestWithAxios(url + "users/chatlistusers/getnotifications.php", data);
        if (res.data?.data)
          dispatch({ type: GET_NOTIFICATION, notif: res.data.data })
      },

  GetTransactions:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {

        const res = await requestWithAxios(url + "users/transaction/gettransactions.php", data);

        dispatch({ type: GET_TRANSACTION, trans: res.data.data })
      },

};

export const reducer: Reducer<any> = (
  state: any | undefined,
  incomingAction: any
): any => {
  if (state === undefined) {
    return Initial.Parent;
  }

  const action = incomingAction;
  switch (action.type) {

    case Request_Login:
      return {
        ...state,
        loginloading: true
      };
    case Login_Success:
      return {
        ...state,
        jwt: action.jwt,
        guardian: action.guardian,
        schoologyPass: action.schoologyPass,
        schoologyUserName: action.schoologyUserName,
        transjwt: action.transjwt
      };

    case Login_fail:
      return {
        ...state,
        loginerror: action.error
      };
    case GET_PARENT_START:
      return {
        ...state,
        guardianLoading: true
      };
    case GET_PARENT_END:
      return {
        ...state,
        guardian: action.res,
        guardianLoading: false
      };
    case GET_PARENT_FAIL:
      return {
        ...state,
        error: action.error,
        guardianLoading: false
      };
    case UPDATE_PARENT_START:
      return {
        ...state,
        updateGuardianLoading: true
      };
    case UPDATE_PARENT_END:
      return {
        ...state,
        updateGuardianLoading: false,
        updateMsg: action.MSG,
        guardian: action.guardian
      };
    case UPDATE_PARENT_FAIL:
      return {
        ...state,
        error: action.error,
        updateGuardianLoading: false
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        updateGuardianLoading: true
      };
    case CHANGE_PASSWORD_END:
      return {
        ...state,
        updateGuardianLoading: false,
        updateMsg: action.MSG
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.error,
        updateGuardianLoading: false
      };

    case CLEAR_PARENT:
      return {
        ...state,
        updateGuardianLoading: false,
        updateMsg: "",
        error: null,
        guardianLoading: false,

        loginerror: "",
        loginloading: false

      };
    case CLEAR_JWT:
      return {
        ...state,
        jwt: ""

      };
    case GET_NOTIFICATION:
      return {
        ...state,
        //update
        notifications: action.notif,
        Link: action.link

      };

    case GET_TRANSACTION:
      return {
        ...state,
        //update
        transactions: action.trans,

      };
    case FETCH_PARENT_DICTIONARY:
      return {
        ...state,
        //update
        dictionary_fetched:true,
        dictionary: action.dictionary,

      };


  }

  return state;
};
