import React, { Component } from "react";
import "./chatList.css";
import ChatListItems from "./ChatListItems";
import { actionCreators as ChatCreator } from "../../../../store/Chat";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Translate } from "../../../../helpers/Translate";

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetMessages: (data: any) => dispatch(ChatCreator.GetMessages(data)),
  GetUsers: (data: any) => dispatch(ChatCreator.GetUsers(data)),
  ReadMsg: (data: any) => dispatch(ChatCreator.ReadMsg(data)),
});

class ChatList extends Component<any, any> {
  t = new Translate();
  constructor(props: any) {
    super(props);
    this.state = {
      allChats: [],
      search: "",
    };
  }

  ChatClicked(mid: any, name: any, to_id: any) {
    //dispatch get messages
    // this.props.GetMessages({source : this.props.guardian.mid , dest : mid } , name)
    this.props.ReadMsg({ to_id: to_id });

    this.props.history.push({
      pathname: "/dashboard/chatcontent",
      search: "?hash=" + mid,
    });
  }

  ineterval = setInterval(() => {
    this.props.GetUsers({
      role: "parent",
      id: this.props.guardian.mid,
      search: this.state.search,
    });
  }, 1000);
  componentDidMount() {
    this.props.GetUsers({ role: "parent", id: this.props.guardian.mid });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps != this.props) {
      let allchats = this.props.Users?.filter((user: any) =>
        user.displayname.toLowerCase().includes(this.state.search.toLowerCase())
      );
      this.setState({ allChats: allchats });
    }
  }
  componentWillUnmount() {
    clearInterval(this.ineterval);
  }

  search(search: any) {
    this.setState({ ...this.state, search });
    let updatedState = { ...this.state, search };
    this.props.GetUsers({
      role: "parent",
      id: this.props.guardian.mid,
      search: search,
    });
    let allchats = this.state.allChats.filter((user: any) =>
      user.displayname.includes(search)
    );
    this.setState({ allChats: allchats });
  }
  render() {
    return (
      <div className="main__chatlist">
        <div className="chatlist__heading">
          <h2>&nbsp;&nbsp;{this.t.translate("Chats")}</h2>
          <button className="btn-nobg">
            <i className="fa fa-ellipsis-h"></i>
          </button>
        </div>
        <div className="chatList__search">
          <div className="search_wrap">
            <input
              type="text"
              placeholder="Search Here"
              required
              onChange={(e: any) => this.search(e.target.value)}
            />
            <button className="search-btn">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div className="chatlist__items">
          {this.state?.allChats?.map((item: any, index: any) => {
            return (
              <ChatListItems
                name={item?.displayname}
                key={item?.id}
                animationDelay={index + 1}
                image={item?.image}
                onClick={() =>
                  this.ChatClicked(item.mid, item.displayname, item.id)
                }
              />
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    Messages: state.Chat.Messages,
    Users: state.Chat.Users,
    guardian: state.Parent.guardian,
  };
}

export default withRouter(
  connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
  )(ChatList as any)
);
