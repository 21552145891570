import axios from "axios";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actionCreators as StudnetCreator } from "../../store/student";
import Select from "react-select";
import Swal from "sweetalert2";
import Line from "./Chart/Line";
import { format } from "date-fns";
import { Translate } from "../../helpers/Translate";

export default function ViewStudents() {
  const dispatch: Dispatch<any> = useDispatch();
  const students = useSelector((state: any) => state.Student.students);
  const guardian = useSelector((state: any) => state.Parent.guardian);
  const t = new Translate()
  useEffect(() => {
    dispatch(StudnetCreator.GetStudents({ id: guardian.id }));
  }, []);

  const handleGoToStPortal = (e: React.MouseEvent, st: any) => {
    let body = st;
    body["guardian"] = guardian;
    axios.post(
      `${process.env.REACT_APP_GO_TO_STDU_PORTAL}auth/parentlogin.php`,
      body
    );
  };
  const handleChangeClub = (e: any, student_id: any) => {
    console.log(e);
    Swal.showLoading();
    axios
      .post(`${process.env.REACT_APP_BASEURL}student/setclub.php`, {
        student_id: student_id,
        club_id: e.value,
      })
      .finally(() => {
        dispatch(StudnetCreator.GetStudents({ id: guardian.id }));
        Swal.close();
      });
  };
  console.log(students, "studetns");
  return (
    <div className="row">
      {students?.map((student: any) => {
        return (
          <div className="col-md-6" key={student.id}>
            <div
              className="card shadow-sm p-3 mb-5 bg-white"
              style={{ width: "22rem", borderRadius: "16px" }}
            >
              <br />
              {student.driveid ? (
                <img
                  src={ student.driveid?.includes("https://")?student.driveid:`https://drive.google.com/uc?id=${student.driveid}&export=download`}
                  className="card-img-top"
                  alt="..."
                  style={{
                    width: "20rem",
                    alignSelf: "center",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <img
                  src="./static/media/avatar.3aaad4fe.jpeg"
                  className="card-img-top"
                  alt="..."
                  style={{
                    width: "20rem",
                    alignSelf: "center",
                    borderRadius: "5px",
                  }}
                />
              )}

              <div className="card-body">
                <h5 className="card-title">{student.fullname}</h5>
                <p className="card-text"></p>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">{t.translate("Grade")} : {student.grade}</li>
                {/* <li className="list-group-item">A second item</li>
                  <li className="list-group-item">A third item</li> */}
              </ul>
              <div className="card-body  pr-0 pl-0">
                <h5 className="card-title">{t.translate("Reinforcement points")} </h5>
                <Link
                  className=" mb-5"
                  to={`/dashboard/viewstudents/${student.id}/Reinforcement-point`}
                >
                  <Line color="blue" Reinforcements={student.Reinforcements} />
                </Link>
              </div>
              <div className="card-body">
                <h5 className="card-title">{t.translate("Preferred Club")}</h5>
                <Select
                  options={student.clubs}
                  defaultValue={student.clubs.find(
                    (i: any) => i.value == student.club
                  )}
                  onChange={(e) => {
                    handleChangeClub(e, student.id);
                  }}
                  isDisabled={
                    format(new Date(), "yyyy-MM-dd") >= student.lockeddate ||
                    !student.lockeddate
                  }
                />
              </div>
              <div className="card-body">
                {/* <a href="#" className="card-link">Card link</a>
                  <a href="#" className="card-link">Another link</a> */}
                <Link
                  className="btn btn-success text-center fw-bold"
                  to={`/dashboard/viewstudents/${student.id}/progress-report`}
                >
                  {t.translate("Progress Card")}
                </Link>

                <a
                  target="_blank"
                  href={`https://student.ivyis.org/backend/store.php?jwt=${student.jwt}`}
                  className="btn btn-primary text-center fw-bold ms-3 text-light"
                >
                  {t.translate("Profile")}
                </a>
                <Link
                  className="btn btn-info text-center fw-bold my-3 " 
                  to={`/dashboard/viewstudents/${student.id}/map-growth`}
                >
                  {t.translate("Map Growth")}
                </Link>
                {student.grade_id < "5" ? (
                  <Link
                    className="btn btn-warning my-3 mx-2 text-center fw-bold"
                    to={`/dashboard/classactivities/${student.id}`}
                  >
                    {t.translate("Daily Activities")}
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
