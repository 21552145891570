import { Component } from "react";

import React from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { actionCreators as TransactionCreator } from '../../../store/Parent';


const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  GetTransactions: (data: any) =>
  dispatch(TransactionCreator.GetTransactions(data)),
 
});





class Subscriptions extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      transactions: [],

    };
  }

  componentDidMount(){
    //parent_id in gettransactions.php
   this.props.GetTransactions({parent_id : this.props.guardian.id });
  
  }

  componentDidUpdate(prevProps :any, prevState: any){
    if(prevProps.transactions !== this.props.transactions){
     
      this.setState({transactions: this.props.transactions})
    }
  }


  render() {
   
    return (

      <div>
        <div className='justify-content-center align-items-center mt3'>
          <p className="text-start text-blue mt-1" style={{ fontSize: '1.2rem' }}>Subscriptions:</p>
          <div className='table-responsive bg-light'>
            <table
              className='table-hover table-bordered text-center'
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>For</th>
                  <th scope='col'>Notes</th>
                  <th scope='col'>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>3000</td>
                  <td>Ramy Samir | Tagamo3-Trip 3-to school |</td>
                  <td>Not fully paid</td>
                  <td>20/12/2020</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="justify-content-end d-flex font-weight-bold mt-3 " style={{ fontSize: "15px" }}>Total amount : 3000 </div>

        </div>



        <div className='justify-content-center align-items-center mt3'>
          <p className="text-start text-blue mt-1" style={{ fontSize: '1.2rem' }}>Transaction:</p>
          <div className='table-responsive bg-light'>
            <table
              className='table-hover table-bordered text-center'
              style={{ width: '100%' }}
            >
              <thead>
                <tr>
                  <th scope='col'>#</th>
                  <th scope='col'>Amount</th>
                  <th scope='col'>For</th>
                  <th scope='col'>Paied</th>
                  <th scope='col'>Balance</th>
                  <th scope='col'>Date</th>
                </tr>
              </thead>
              <tbody>
              {
          this.state.transactions?.map((item:any, index:any) => {
            return (
             <tr key={index}>
               <th scope='col'> {item.id}    </th>            
               <th scope='col'>{item.fees} </th>  
               <th scope='col'>{item.student_id} </th>  
               <th scope='col'>   {item.paied} </th>  
               <th scope='col'>   {item.balance} </th>  
               <th scope='col'>    {item.date} </th>  
             </tr>

            
               
            );
          
          })}
              
              </tbody>
            </table>
          </div>
          <div className="justify-content-end d-flex font-weight-bold mt-3 " style={{ fontSize: "15px" }}>Total amount :  </div>
          
        </div>

      </div>

    )
  }

}



function mapStateToProps(state: any) {
  return {
    guardian: state.Parent.guardian,
    transactions: state.Parent.transactions,
    

  };
}

export default withRouter(
  connect(
      mapStateToProps, // Selects which state properties are merged into the component's props
      mapDispatchToProps // Selects which action creators are merged into the component's props
  )(Subscriptions as any)
);