import { Reducer } from "redux";
import { AppThunkAction } from ".";
import { parseJwt, requestWithAxios } from "../helpers/func";
import { GET_CHAT_USERS, GET_MESSAGES, GET_UNREAD_MESSAGES } from "./ActionTypes";
import * as Initial from "./initialStatus";

import { url } from './initialStatus'
/**
 * status
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error
 */

interface AddAssessment {
  type: string;
  msg: string;
  Questions: [] | undefined;
}

type KnownAction = AddAssessment;

export const actionCreators = {


  GetMessages:
    (data: any ): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
       
        const res = await requestWithAxios(url + "users/chatlistusers/getmessages.php", data);
       
        dispatch({ type: GET_MESSAGES , msg:res.data.data.messages , image : res.data.data.image   , name : res.data.data.name, count: res.data.data.count[0].count})
      },   
  SaveMsg:
    (data: any ): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
       
        const res = await requestWithAxios(url + "users/chatlistusers/savemessage.php", data);
     
        // dispatch({ type: GET_MESSAGES , msg:res.data.data , name :name})
      },   
      ReadMsg:
      (data: any ): AppThunkAction<any | any> =>
        async (dispatch, getState) => {
          const res = await requestWithAxios(url + "users/chatlistusers/readmsg.php", data);
          dispatch(actionCreators.GetUnReadMessages({source: 50}));
          // dispatch({ type: GET_MESSAGES , msg:res.data.data , name :name})

        }, 
  GetUsers:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {
       
        const res = await requestWithAxios(url + "users/chatlistusers/getusers.php", data);
       
        dispatch({ type: GET_CHAT_USERS , users :res.data.data})
      },  
      
      GetUnReadMessages:
      (data: any ): AppThunkAction<any | any> =>
        async (dispatch, getState) => {
         
          const res = await requestWithAxios(url + "users/chatlistusers/getunreadmessage.php", data);
          if(res.data?.data)
          dispatch({ type: GET_UNREAD_MESSAGES , UnReadMessages:res.data.data })
        },   
     

      
};

export const reducer: Reducer<any> = (
  state: any | undefined,
  incomingAction: any
): any => {
  if (state === undefined) {
    return Initial.Chat;
  }

  const action = incomingAction;
  switch (action.type) {

    case GET_MESSAGES:
      return {
        ...state,
        //update
        Messages: action.msg ,
        image :action.image,
        name :action.name,
        count: action.count
      
      };
    case GET_CHAT_USERS:
      return {
        ...state,
        //update
        Users: action.users
      
      };
      case GET_UNREAD_MESSAGES:
      return {
        ...state,
        //update
        UnReadMessages: action.UnReadMessages ,
       
      
      };
    
  }

  return state;
};
