import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as StudnetCreator } from "../../store/student";
import DoPayment from "../onlinepayment/pay";
import Modal from "react-modal";
import {
  Modal as Modal2,
  ModalHeader as ModalHeader2,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import $ from "jquery";
import "./ewallet.css";
import axios from "axios";
import { url } from "../../store/initialStatus";
import swal from "sweetalert";
import { Link, useHistory } from "react-router-dom";
import { Translate } from "../../helpers/Translate";

const MySwal = withReactContent(Swal);

export default function EWallet() {
  const [toggler, setToggler] = useState(true);
  const dispatch: Dispatch<any> = useDispatch();
  const [restrict, setRestrict] = useState(0);
  const [controlButton, setControlButton] = useState(false);
  const students = useSelector((state: any) => state.Student.students);
  const guardian = useSelector((state: any) => state.Parent.guardian);
  const [isModalOpen, ShowModal] = useState<boolean>(false);
  const [SelectedStd, SelectStd] = useState<any>(null);
  const [ModalHeader, SetModalHeader] = useState<any>(null);
  const [Pay, SetPayment] = useState<any>(null);
  const [Amount, SetAmount] = useState<any>("0");
  const t = new Translate();
  useEffect(() => {
    dispatch(StudnetCreator.GetStudents({ id: guardian.id }));
  }, []);
  let history = useHistory();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const toggle = () => ShowModal(!isModalOpen);
  const setStudentLimit = (Amount: any, SelectedStd: any, restrict: any) => {
    Swal.showLoading();
    axios
      .post(`${url}payment/controle-wallet.php`, {
        Elimit: Amount,
        student_id: SelectedStd,
        Locked: restrict,
      })
      .then((res) => {
        console.log(res);
        Swal.fire("Saved Successfully!", "", "success");
      })
      .then(() => {
        ShowModal(false);
        dispatch(StudnetCreator.GetStudents({ id: guardian.id }));
      });
  };

  function paymentFinished(e: any) {
    SetPayment(null);
    if (e === "success") {
      $("#" + SelectedStd + "balance").text(
        parseInt(Amount) + parseInt($("#" + SelectedStd + "balance").text())
      );
      console.log("Success Payment");
    }
  }

  //const rechargeBody =
  Modal.setAppElement("body");
  if (Pay !== null) {
    return (
      <div
        style={{
          zIndex: 100,
          display: "block",
          background: "white",
          border: "0px none transparent",
          overflow: "hidden auto",
          visibility: "visible",
          margin: "0px",
          padding: "0px",
          position: "fixed",
          left: "0px",
          top: "0px",
          width: "100%",
          height: "100%",
        }}
      >
        {Pay}
      </div>
    );
  } else
    return (
      <div className="row">
        <div className="col-12 y-2">
          <h1>{t.translate("IVY E-Wallet")}</h1>
        </div>
        <div className="col-12">
          <p>
            {t.translate(
              "Manage your children’s finances efficiently Cultivate smart spending habits in your child by tracking and managing their finances with a IVY E-Wallet."
            )}
            <br />
            {t.translate(
              "You can reserve daily meals from IVY Resturant for your children , using the IVY E-Wallet."
            )}
          </p>
        </div>
        <div className="col-12">
          <h2>
            {t.translate(
              "A safe, simple, and smart way of managing your children’s finances"
            )}
          </h2>
          <p>
            {" "}
            {t.translate(
              "IVY E-Wallet is a modern way of establishing the right balance of control and independence for your kids."
            )}
            {t.translate(
              "This solution enables parents to view all their kids’ finances along with allowing them to set pocket money, tracking spending, monitoring savings goals, and locking cards."
            )}
          </p>
          <p>
            {t.translate("Both parents and child have an app on their phones.")}
            {t.translate(
              "Parents can simply add money to the wallet which they intend to give to their kids."
            )}
            {t.translate(
              "Once the money is added, the child can use it like a normal debit card or a mobile wallet."
            )}
          </p>
          <p>
            {t.translate(
              "This solution also comes with a feature which enables parents to cap daily spend limits on the application."
            )}
            {t.translate(
              "Moreover, it also allows parents to put a cap on expenditures across various categories."
            )}
            {t.translate(
              "These features help parents to ensure that their kid is not overspending his/her pocket money."
            )}
          </p>
        </div>

        <div className="col-12">
          {t.translate("")}
          <h2>
            {t.translate(
              "You have two Options to recharge you children E-Wallets"
            )}
          </h2>
          <u>
            <li>
              {t.translate(
                "Online using your card by clicking on the recharge button"
              )}
            </li>
            <li>{t.translate("Cash on the finance office in IVY Schools")} </li>
          </u>
        </div>
        {students?.map((student: any) => {
          return (
            <div className="col-12 col-lg-4" key={student.id}>
              <div
                className="card shadow-sm p-3 mb-5 bg-white"
                style={{ width: "22rem", borderRadius: "16px" }}
              >
                <br />
                {student.driveid ? (
                  <img
                    src={student.driveid?.includes("https://")?student.driveid :`https://drive.google.com/uc?id=${student.driveid}&export=download`}
                    className="card-img-top"
                    alt="..."
                    style={{
                      width: "20rem",
                      alignSelf: "center",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <img
                    src="./static/media/avatar.3aaad4fe.jpeg"
                    className="card-img-top"
                    alt="..."
                    style={{
                      width: "20rem",
                      alignSelf: "center",
                      borderRadius: "5px",
                    }}
                  />
                )}

                <div className="card-body">
                  <h5 className="card-title">{student.fullname}</h5>
                  <p className="card-text"></p>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {t.translate("Grade")} : {student.grade}
                  </li>
                  <li className="list-group-item">
                    {t.translate("Wallet Credit")} :{" "}
                    <span id={`${student.id}balance`}>{student.balance}</span>{" "}
                    {t.translate("LE.")}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <button
                      onClick={() => {
                        history.push({
                          pathname: `/dashboard/ewallet/transaction/${student.id}`,
                          state: {
                            id: student.id,
                            fullname: student.fullname,
                            Grade: student.grade,
                          },
                        });
                      }}
                      style={{ width: "100%" }}
                      className="btn btn-success"
                    >
                      {" "}
                      {t.translate("View Transactions")}
                    </button>
                  </li>
                  {/* <li className="list-group-item">A second item</li>
                  <li className="list-group-item">A third item</li> */}
                </ul>
                <div style={{ display: "flex" }} className="card-body">
                  <button
                    className="btn btn-primary m-2"
                    onClick={() => {
                      SelectStd(student.id);
                      //SetModalBody(rechargeBody);
                      SetModalHeader(
                        t.translate("Charging your child E-Wallet...")
                      );
                      ShowModal(true);
                      setControlButton(false);
                    }}
                  >
                    {t.translate("Charge Wallet")}{" "}
                  </button>
                  <button
                    className="btn btn-danger m-2"
                    onClick={() => {
                      SelectStd(student.id);
                      console.log(SelectedStd, students);
                      //SelectedStudent(student);
                      SetAmount(student.elimit);
                      setRestrict(student.locked);
                      setToggler(+student.locked === 0);
                      SetModalHeader(t.translate("Set Maximum Credit Per Day"));
                      ShowModal(true);
                      setControlButton(true);
                    }}
                  >
                    {" "}
                    {t.translate("Control Wallet")}{" "}
                  </button>

                  {/* <a href="#" className="card-link">Card link</a>
                  <a href="#" className="card-link">Another link</a>  */}
                </div>
              </div>
            </div>
          );
        })}
        {controlButton ? (
          <Modal2
            id="ControlWalletModal"
            style={{ marginTop: "150px", maxWidth: "500px" }}
            isOpen={isModalOpen}
            toggle={toggle}
          >
            <ModalHeader2 toggle={toggle}>
              {t.translate("State your child's E-Wallet daily Limit")}
            </ModalHeader2>
            <ModalBody>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="form-group row">
                    <p className="col-12">
                      {" "}
                      {t.translate("Please Enter the amount.")}{" "}
                    </p>
                    <div className="col-5">
                      <input
                        className="form-control "
                        value={Amount ?? 0}
                        type="number"
                        onChange={(e) => {
                          SetAmount(e.target.value);
                          //console.log(Amount)
                        }}
                      />
                    </div>
                    <div className="col-1">
                      <p className="mt-2"> {t.translate("LE.")} </p>
                    </div>

                    <div className="col-12">
                      <Row>
                        <Col xs={3} sm={3} style={{ marginTop: "15px" }} md={3}>
                          <span style={{ marginTop: "-15px" }}>
                            {" "}
                            {t.translate("Suspended")}{" "}
                          </span>{" "}
                        </Col>

                        <Col xs={2} sm={2} md={2}>
                          <label className="switch">
                            <input
                              style={{ marginTop: "15px" }}
                              type="checkbox"
                              checked={toggler}
                              onChange={() => {
                                toggler ? setRestrict(1) : setRestrict(0);
                                setToggler(!toggler);
                              }}
                            />
                            <span className="slider round"></span>;
                          </label>
                        </Col>
                        <Col xs={2} sm={2} style={{ marginTop: "15px" }} md={1}>
                          {" "}
                          <span> {t.translate("Active")} </span>{" "}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                style={{ margin: "10px", width: "200px", height: "40px" }}
                className="btn btn-success"
                onClick={() => {
                  Swal.showLoading();
                  if (Amount > 0) {
                    setStudentLimit(Amount, SelectedStd, restrict);
                  } else {
                    MySwal.fire({
                      title: <p>Error!</p>,
                      footer: "Copyright 2021",
                      html: (
                        <i>{t.translate("Please Enter a valid amount!")}</i>
                      ),
                      icon: "error",
                      didOpen: () => {
                        // `MySwal` is a subclass of `Swal`
                        //   with all the same instance & static methods
                        //MySwal.clickConfirm()
                      },
                    });
                  }
                }}
              >
                {t.translate("Submit")}
              </button>
              <button
                className="btn btn-danger"
                style={{ marginLeft: "auto" }}
                onClick={() => ShowModal(false)}
              >
                {t.translate("Cancel")}
              </button>
            </ModalFooter>
          </Modal2>
        ) : (
          <Modal
            isOpen={isModalOpen}
            onAfterOpen={() => {}}
            onRequestClose={() => {}}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              className="top-0 border-bottom d-flex m-0 p-0"
              style={{ width: "100%" }}
            >
              <h2
                style={{
                  marginRight: "auto",
                  fontSize: "18px",
                  display: "flex",
                  paddingRight: "100px",
                }}
              >
                {ModalHeader}
              </h2>
              <span
                className="btn"
                style={{
                  cursor: "pointer",
                  marginLeft: "auto",
                  fontSize: "20px",
                  display: "flex",
                  color: "red",
                  marginTop: "-10px",
                }}
                onClick={() => ShowModal(false)}
              >
                X
              </span>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div className="form-group row">
                  <p className="col-12">
                    {" "}
                    {t.translate("Please Enter the amount.")}{" "}
                  </p>
                  <div className="col-6">
                    <input
                      className="form-control "
                      value={Amount}
                      type="number"
                      onChange={(e) => {
                        SetAmount(e.target.value);
                        console.log(Amount);
                      }}
                    />
                  </div>
                  <div className="col-1">
                    <p className="mt-2"> {t.translate("LE.")} </p>
                  </div>
                </div>
              </div>
            </div>
            <ModalFooter
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (Amount > 0) {
                    SetModalHeader(t.translate("Initiating the Payment..."));
                    SetPayment(
                      <DoPayment
                        amount={Amount}
                        serviceName={
                          t.translate("Charging E-Wallet for") +
                          ": " +
                          SelectedStd
                        }
                        std={SelectedStd}
                        code="EW"
                        onFinish={paymentFinished}
                      />
                    );
                    ShowModal(false);
                  } else {
                    MySwal.fire({
                      title: <p>Error!</p>,
                      footer: "Copyright 2021",
                      html: (
                        <i>{t.translate("Please Enter a valid amount!")}</i>
                      ),
                      icon: "error",
                      didOpen: () => {
                        // `MySwal` is a subclass of `Swal`
                        //   with all the same instance & static methods
                        //MySwal.clickConfirm()
                      },
                    });
                  }
                }}
              >
                {t.translate("Continue")}
              </button>

              <button
                className="btn btn-danger"
                onClick={() => ShowModal(false)}
              >
                {t.translate("Cancel")}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
}
