import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./cvdet.css";
import { Translate } from "../../../helpers/Translate";

const ClinicVisitDetails: React.FC = () => {
  let t = new Translate();

  const { id } = useParams<{ id?: string }>(),
    [clinicVisit, setClinicVisit] = useState({
      id: "",
      student_id: "",
      fullname: "",
      grade: "",
      date: "",
      time: "",
      complain: "",
      measuretaken: "",
      recommendation: "",
    }),
    [comments, setComments] = useState([
      {
        id: "",
        commentator: "",
        commentator_id: "",
        comment: "",
      },
    ]),
    [comment, setComment] = useState(""),
    [user, setUser] = useState({
      id: "",
      displayname: "",
      driveid: "",
    });

  useEffect(() => {
    try {
      console.log(id);
      axios
        .post(`${process.env.REACT_APP_BASEURL}student/getclinicvisit.php`, {
          cvId: id,
        })
        .then((res) => {
          console.log(res.data.data);
          setClinicVisit(res.data.data);
        });

      axios
        .post(`${process.env.REACT_APP_BASEURL}users/getcvcomments.php`, {
          cvId: id,
        })
        .then((res) => {
          console.log(res.data.data);
          setUser(res.data.data.user);
          setComments(res.data.data.comments);
        });
    } catch {
      Swal.fire("Error while grapping interventions!", "", "error");
    }
  }, []);

  const sectionContent = (html: any) => {
    return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  };

  const handleCommentChange = (e: any) => {
    console.log(e.target.value);
    setComment(e.target.value);
  };

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      axios
        .post(`${process.env.REACT_APP_BASEURL}users/cvcomment.php`, {
          comment: comment,
          cvId: id,
        })
        .then((res) => {
          Swal.fire("Comment Added Successfully", "", "success");
          setComment("");
          setComments(res.data.data);
        });
    } catch {
      Swal.fire("Error while sending your comment", "", "error");
    }
  };

  return (
    <>
      <div className="header mt-5">
        <h3 className="title">
          {t.translate("Clinic Visit No.")}
          {clinicVisit.id}
        </h3>
      </div>

      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardBody className="bg-light container-fluid">
            <div>
              <div>
                <h6 className="fw-bold fs-5">
                  {t.translate("Student")}: &nbsp;
                  <span className="fs-6 fw-bold text-danger">
                    {" "}
                    {clinicVisit.student_id} : {clinicVisit.fullname}
                  </span>
                </h6>
                <h6 className="fw-bold fs-5">
                  {t.translate("Date")} - {t.translate("Time")}: &nbsp;
                  <span className="fs-6 fw-bold text-danger">
                    {clinicVisit.date} - {clinicVisit.time}
                  </span>
                </h6>
                <h6 className="my-3 fw-bold fs-5">
                  {t.translate("Complain")}:
                </h6>
                <p className="fs-6 fw-bold ms-3 text-black">
                  {sectionContent(clinicVisit.complain)}
                </p>
                <h6 className="my-3 fw-bold fs-5">
                  {" "}
                  {t.translate("Measure Taken")}:
                </h6>
                <p className="fs-6 fw-bold ms-3 text-black">
                  {sectionContent(clinicVisit.measuretaken)}
                </p>
              </div>
            </div>
            <div>
              <div>
                <h6 className="my-3 fw-bold fs-5">
                  {t.translate("Recommendation")}:
                </h6>
                <p className="fs-6 fw-bold ms-3 text-black">
                  {sectionContent(clinicVisit.recommendation)}
                </p>
              </div>
            </div>
            <hr />
            <div>
              <div>
                <h6 className="fw-bold fs-5"> {t.translate("Comments")}: </h6>
              </div>
              <hr />
              <div className="mb-5">
                {comments.map((comment: any, key: any) => {
                  if (comment.user_id == user.id) {
                    return (
                      <>
                        <div
                          key={key}
                          className="row justify-content-start text-left align-items-start mb-3"
                        >
                          <div className="col-12">
                            {user.driveid ? (
                              <img
                                className="d-inline-block responsive rounded rounded-circle"
                                style={{ width: "80px", height: "50px" }}
                                src={user.driveid?.includes("https://")?user.driveid :`https://drive.google.com/uc?id=${user.driveid}&export=download`}
                                alt="user-image"
                              />
                            ) : (
                              <img
                                className="d-inline-block responsive"
                                style={{ width: "80px", height: "50px" }}
                                src="https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg"
                              />
                            )}
                            <h6 className="d-inline-block mx-2 fw-bold">
                              {user.displayname}{" "}
                              <small className="ps-2">{comment.date}</small>
                            </h6>
                          </div>
                          <div className="col-12 mt-3 pt-4 ps-5">
                            <p>{comment.comment}</p>
                          </div>
                        </div>
                        <hr className="w-100" />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div
                          key={key}
                          className="row justify-content-end text-right align-items-end mb-3"
                        >
                          <div className="col-12">
                            <h6 className="d-inline-block mx-2 fw-bold">
                              <small className="pr-2">{comment.date}</small>{" "}
                              {comment.username}
                            </h6>
                            {comment.driveid ? (
                              <img
                                className="d-inline-block responsive rounded rounded-circle"
                                style={{ width: "80px", height: "50px" }}
                                src={ comment.driveid?.includes("https://")?comment.driveid :`https://drive.google.com/uc?id=${comment.driveid}&export=download`}
                                alt="user-image"
                              />
                            ) : (
                              <img
                                className="d-inline-block responsive"
                                style={{ width: "80px", height: "50px" }}
                                src="https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg"
                              />
                            )}
                          </div>
                          <div className="col-12 mt-3 pt-4 pr-5">
                            <p>{comment.comment}</p>
                          </div>
                        </div>
                        <hr className="w-100" />
                      </>
                    );
                  }
                })}
              </div>
            </div>
            <div>
              <div className="mt-5 text-center">
                <textarea
                  className="w-100 p-2 comment"
                  value={comment}
                  onChange={(e) => handleCommentChange(e)}
                  placeholder="Type your comment....."
                ></textarea>
                <button
                  className="btn btn-primary fs-6 fw-bold w-50 mt-3 mx-auto"
                  onClick={(e) => handleSubmit(e)}
                >
                  {t.translate("Submit")}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ClinicVisitDetails;
