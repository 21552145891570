import { Reducer } from "redux";
import { AppThunkAction } from ".";
import { parseJwt, requestWithAxios } from "../helpers/func";
import { GET_CHAT_USERS, GET_ExcusesReasons, GET_MESSAGES, GET_STUDENTS, GET_UNREAD_MESSAGES } from "./ActionTypes";
import * as Initial from "./initialStatus";

import { url } from './initialStatus'
/**
 * status
 *  204 => ok same data in server
 *  401 => Error unauthorized
 *  403 => Error Forbidden not loggedin
 *  500 => Error server error
 *  -1 => Error
 */

interface AddAssessment {
  type: string;
  msg: string;
  Questions: [] | undefined;
}

type KnownAction = AddAssessment;

export const actionCreators = {


 
  GetStudents:
    (data: any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {      
        const res = await requestWithAxios(url + "student/getstudents.php", data);
        dispatch({ type: GET_STUDENTS , students :res.data.data})
      },  
  GetExcusesReasons:
    (): AppThunkAction<any | any> =>
      async (dispatch, getState) => {             
        const res = await requestWithAxios(url + "student/excuse/getexcusereasons.php", {});
        dispatch({ type: GET_ExcusesReasons , ExcusesReasons :res.data.data})
      },  
  AddExcuse:
    (data:any): AppThunkAction<any | any> =>
      async (dispatch, getState) => {             
        const res = await requestWithAxios(url + "student/excuse/addexcuse.php", data);
       console.log(res)
      
      },  



};

export const reducer: Reducer<any> = (
  state: any | undefined,
  incomingAction: any
): any => {
  if (state === undefined) {
    return Initial.Student;
  }

  const action = incomingAction;
  switch (action.type) {

    
    case GET_STUDENTS:
      return {
        ...state,
        //update
        students: action.students
      
      };
    case GET_ExcusesReasons:
      return {
        ...state,
        //update
        ExcusesReasons: action.ExcusesReasons
      
      };
     
    
  }

  return state;
};
