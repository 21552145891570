export const Request_Login = "Request_Login";
export const Login_fail = "Login_fail";
export const Login_Success = "Login_Success";
export const Login_Loading = "Login_Loading";
export const RECEIVE_WEATHER_FORECASTS = "RECEIVE_WEATHER_FORECASTS";
export const REQUEST_WEATHER_FORECASTS = "REQUEST_WEATHER_FORECASTS";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const CLEAR_USER_LOGOUT = "CLEAR_USER_LOGOUT";
export const GET_USER = "GET_USER";
export const DEL_USER = "DEL_USER";
export const ADD_USER = "ADD_USER";
export const Edit_USER = "Edit_USER";
export const GET_USERS = "GET_USERS";
export const Loading_USERS = "Loading_USERS";
export const Error_USERS = "ERROR_USERS";
export const CC_DO_ACTION = "CC_DO_ACTION";
export const GET_PARENT_START = "GET_PARENT_START";
export const GET_PARENT_END = "GET_PARENT_END";
export const GET_PARENT_FAIL = "GET_PARENT_FAIL";
export const UPDATE_PARENT_START = "UPDATE_PARENT_START";
export const UPDATE_PARENT_END = "UPDATE_PARENT_END";
export const UPDATE_PARENT_FAIL = "UPDATE_PARENT_FAIL";
export const CLEAR_PARENT = "CLEAR_PARENT";
export const LOGOUT = "LOGOUT";

export const FETCH_PARENT_DICTIONARY = "FETCH_PARENT_DICTIONARY";
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_END = "CHANGE_PASSWORD_END";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
export const CLEAR_JWT = "CLEAR_JWT";



//Chat
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_CHAT_USERS = "GET_CHAT_USERS";
export const GET_UNREAD_MESSAGES = "GET_UNREAD_MESSAGES";

//notifications
export const GET_NOTIFICATION = "GET_NOTIFICATION";

//transactions
export const GET_TRANSACTION = "GET_TRANSACTION";

//Students 
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_ExcusesReasons = "GET_ExcusesReasons";
