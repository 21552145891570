import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../../store/initialStatus";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { stringify } from "querystring";
import { Translate } from "../../../helpers/Translate";

const CafeteriaMenu = () => {
  const History = useHistory();
  const [Items, SetItems] = useState<any>([]);
  const [Students, SetStudents] = useState<any>([]);
  const [Preference, SetPreference] = useState<any>([]);
  const t = new Translate();
  useEffect(() => {
    axios.get(`${url}alloweditems/getutils.php`).then((res: any) => {
      console.log(res.data);
      SetStudents(res.data.data.students);
      SetItems(res.data.data.items);
      let R = res.data.data.relations;
      res.data.data.students.forEach((s: any) => {
        res.data.data.items.forEach((item: any) => {
          //console.log(item);
          let i = R.findIndex(
            (p: any) => p.student_id === s.id && p.itemcode === item.ItemCode
          );
          if (i === -1) {
            R.push({
              itemcode: item.ItemCode,
              student_id: s.id,
              allowed: true,
            });
          }
        });
      });
      SetPreference(R);
      $("#datatable").DataTable({});
    });
  }, []);

  const ChangePreference = (std_id: string, itemCode: string, val: boolean) => {
    SetPreference((prev: any) => {
      let newArray = [...prev];
      let i = newArray.findIndex(
        (p: any) => p.student_id === std_id && p.itemcode === itemCode
      );
      if (i !== -1) {
        newArray[i].allowed = val;
        return newArray;
      } else {
        newArray.push({ itemcode: itemCode, student_id: std_id, allowed: val });
        return newArray;
      }
    });
  };

  const defaultPref = (std_id: string, val: boolean) => {
    SetPreference((prev: any) => {
      let newArray = [...prev];
      Items.forEach((item: any) => {
        //console.log(item);
        let i = newArray.findIndex(
          (p: any) => p.student_id === std_id && p.itemcode === item.ItemCode
        );
        if (i !== -1) {
          newArray[i].allowed = val;
        } else {
          newArray.push({
            itemcode: item.ItemCode,
            student_id: std_id,
            allowed: val,
          });
        }
      });
      //console.log(newArray);
      return newArray;
    });
  };

  const Submit = () => {
    Swal.showLoading();
    console.log(Preference);
    axios
      .post(`${url}alloweditems/saveitems.php`, Preference)
      .then((res: any) => {
        console.log(res);
        if (res.data.data === "success") {
          Swal.fire("Saved Successfully", "", "success").then(() => {
            History.push("dashboard/home");
          });
        } else {
          Swal.close();
        }
      })
      .catch(() => {
        Swal.close();
      });
  };

  return (
    <div className="content">
      <div className="table-responsive">
        <table id="datatable" className="table table-striped">
          <thead>
            <tr>
              <th>{t.translate("Item name")} (EN)</th>
              <th>{t.translate("Item name")} (AR)</th>
              <th>
                {t.translate("Price")} ({t.translate("LE")})
              </th>
              {Students.map((s: any) => {
                return (
                  <th key={s.id}>
                    {`${s.id}:${s.fullname}`}
                    <input
                      type="radio"
                      onChange={() => {
                        defaultPref(s.id, true);
                      }}
                      className="btn-check"
                      name={s.id + "-Gstatus"}
                      id={s.id + "-classroom"}
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-success"
                      htmlFor={s.id + "-classroom"}
                    >
                      {t.translate("Allow")}
                    </label>

                    <input
                      type="radio"
                      onChange={() => {
                        defaultPref(s.id, false);
                      }}
                      className="btn-check"
                      name={s.id + "-Gstatus"}
                      id={s.id + "-absent"}
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-danger"
                      htmlFor={s.id + "-absent"}
                    >
                      {t.translate("Don't Allow")}
                    </label>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Items.map((item: any) => {
              return (
                <tr key={item.ItemCode}>
                  <td> {item.ItemEname} </td>
                  <td> {item.ItemAname} </td>
                  <td>{item.SellingPrice}</td>
                  {Students.map((s: any) => {
                    //ai.itemcode , ai.student_id , ai.allowed
                    let allowed = true;
                    let temp = Preference.find(
                      (p: any) =>
                        p.student_id === s.id && p.itemcode === item.ItemCode
                    );
                    //console.log(Preference , s.id, item.ItemCode);
                    if (temp) {
                      if (temp.allowed == false) {
                        allowed = false;
                      }
                    }
                    return (
                      <td key={`${item.ItemCode}-${s.id}`}>
                        <input
                          type="radio"
                          onChange={() => {
                            ChangePreference(s.id, item.ItemCode, true);
                          }}
                          className="btn-check"
                          name={`${item.ItemCode}-${s.id}`}
                          id={`${item.ItemCode}-${s.id}-Allowed`}
                          autoComplete="off"
                          checked={allowed}
                        />
                        <label
                          className="btn btn-outline-success"
                          htmlFor={`${item.ItemCode}-${s.id}-Allowed`}
                        >
                          {t.translate("Allow")}
                        </label>

                        <input
                          type="radio"
                          onChange={() => {
                            ChangePreference(s.id, item.ItemCode, false);
                          }}
                          className="btn-check"
                          name={`${item.ItemCode}-${s.id}`}
                          id={`${item.ItemCode}-${s.id}-no`}
                          autoComplete="off"
                          checked={!allowed}
                        />
                        <label
                          className="btn btn-outline-danger"
                          htmlFor={`${item.ItemCode}-${s.id}-no`}
                        >
                          {t.translate("Don't Allow")}
                        </label>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <button
        className="btn btn-warning w-100"
        onClick={() => {
          Submit();
        }}
      >
        {t.translate("Save !!!")}{" "}
      </button>
    </div>
  );
};

export default CafeteriaMenu;
