import React, { Dispatch, useEffect, useState } from 'react';
import { Row, Col, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as StudnetCreator } from "../../../store/student";
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Swal from 'sweetalert2';
import { url } from '../../../store/initialStatus';
import axios from 'axios';
import './excuses.css'
import ClipLoader from "react-spinners/ClipLoader";
import { Translate } from '../../../helpers/Translate';


interface IformData {
  selectedStudents: string[];
  reason_id: string;
  date: Date;
  description: string;
  status: string;
  attachments: any[];
  selectedFile: [];

}

const ExcuseModal: React.FC<any> = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const students = useSelector((state: any) => state.Student.students);
  const ExcusesReasons = useSelector((state: any) => state.Student.ExcusesReasons);
  const guardian = useSelector((state: any) => state.Parent.guardian);
  const studentsOptions: any = [];
  const ReasonsOptions: any = [];
  const [selectedReason, setSelectedReason] = useState<any>();
  const [attachModal, setAttachModal] = useState(false);

  let [loading, setLoading] = useState(false);
  students?.forEach((student: any) => {
    studentsOptions.push({ value: student.id, label: student.fullname })
  });
  ExcusesReasons?.forEach((reason: any) => {
    ReasonsOptions.push({ value: reason.id, label: reason.reason })
  });
  useEffect(() => {
    dispatch(StudnetCreator.GetStudents({ id: guardian.id }))
    dispatch(StudnetCreator.GetExcusesReasons())
  }, [])
  const [formData, setFormData] = useState({
    selectedStudents: [],
    reason_id: "",
    date: null,
    description: "",
    status: "",
    attachments: [],
    selectedFile: []

  });


  console.log("nuber ", formData.selectedStudents?.length)


  const intialFormData = () => {
    setFormData({
      selectedStudents: [],
      reason_id: "",
      date: null,
      description: "",
      status: "",
      attachments: [],
      selectedFile: []
    })

    setSelectedReason("");
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSelectStudent = (e: any) => {
    setFormData(prevState => ({
      ...prevState, selectedStudents: e
    }))
  }
  const handleSelectReason = (e: any) => {
    setFormData(prevState => ({
      ...prevState, reason_id: e.value
    }))
    setSelectedReason(e)
  }

  const submitHandler = (e: any) => {

    e.preventDefault();

    Swal.fire({
      title: 'Please confirm your request. ',
      showDenyButton: true,
      confirmButtonText: `I confirm`,
      denyButtonText: `Cancel`,
      icon: 'warning',


    }).then((e) => {
      if (e.isConfirmed) {


        Swal.fire({
          html: '<div style="color:blue;"> Loading....... </div>',
          showConfirmButton: false

        })


        const data = new FormData();
        data.append("selectedStudents", JSON.stringify(formData.selectedStudents));
        data.append("reason_id", formData.reason_id);
        data.append("description", JSON.stringify(formData.description));
        data.append("date", JSON.stringify(formData.date));
        console.log("guardian", guardian)
        data.append("guardian_id", guardian.guardian_id);
        formData.attachments.map((attachment: any) => {
          data.append(attachment.id, attachment.file)
        })
        axios.post(`${url}student/excuse/addexcuse.php`, data).then((res: any) => {

          if (res.data.data === "success") {
            Swal.fire("Sent Successfully", "", "success").then(() => {
              Swal.close();
              props.setModal(false);
              intialFormData();
              props.getExcuses();
            });
          } else {
            Swal.close();
            props.setModal(false);
            intialFormData();
          }

        }).catch(() => {
          Swal.close();
        })


      }
    })



  }
  const submitFileHandler = (e: any) => {

    setAttachModal(false)
    let file: any = formData.selectedFile[0];
    e.preventDefault();
    let attachments: any = [...formData.attachments];
    let obj = {
      id: Math.random(),
      filename: file.name,
      file: file
    }
    attachments.push(obj)
    setFormData(prevState => ({
      ...prevState, attachments: attachments
    })

    )

  }


  const t = new Translate()
  return (
    <>
      <Modal isOpen={props.modal} className="sizeModal" >
        <ModalHeader>{t.translate("Request")} {t.translate("excuse")} :</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitHandler}>


            <FormGroup>


              {formData.selectedStudents?.length > 0 && formData.reason_id && formData.date && formData.description ?
                null : (
                  <Row className="mb-3 text-center" style={{ color: "red" }}>
                    <Col md={12}>
                      <div>
                        {t.translate("Please fill all fields")}
                      </div>
                    </Col>
                  </Row>
                )
              }

              <Row >
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="emergencyname1">{t.translate("Students")}</label>
                    <Select
                      closeMenuOnSelect={true}

                      isMulti
                      options={studentsOptions}
                      onChange={(e) => {
                        handleSelectStudent(e);
                      }}
                      value={formData.selectedStudents}
                    />

                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="reason" className="green">{t.translate("Reason")}</label>
                    <Select
                      closeMenuOnSelect={true}

                      required
                      options={ReasonsOptions}
                      onChange={(e) => {
                        handleSelectReason(e);
                      }}
                      value={selectedReason}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="date">{t.translate("Date")}</label>
                    <DatePicker

                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        height: "35px"
                      }}
                      containerStyle={{
                        width: "100%"
                      }}
                      calendarPosition="bottom-center"
                      multiple
                      value={formData.date}
                      required={true}
                      onChange={(e: any) => {
                        console.log(e)
                        setFormData(prevState => ({ ...prevState, date: e }))
                      }}
                      plugins={[
                        <DatePanel />
                      ]}
                    />

                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="description">{t.translate("Description")}</label>
                    <Input type="textarea" name="description" required placeholder="Description" value={formData.description} onChange={(e) => handleChange(e)} />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <button className="btn btn-primary mb-5" style={{ display: 'block' }} onClick={(e) => { e.preventDefault(); setAttachModal(true) }}  > {t.translate("Add")}{" "}{t.translate("Attachment")} </button>
                    <label htmlFor="description">{t.translate("ِAttachments")}</label>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <tr>
                          <th>#</th>
                          <th>{t.translate("Attachment")}  </th>
                          <th>{t.translate("Actions")}  </th>

                        </tr>

                        <tbody>

                          {formData.attachments?.map((attachment: any, index: any) => {
                            return (<>
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{attachment.filename}</td>
                                <td><button className="btn btn-danger" onClick={(e: any) => {
                                  e.preventDefault();
                                  let attachments = [...formData.attachments];
                                  let filtered = attachments.filter((attach: any) => attach.id != attachment.id)
                                  setFormData(prevState => ({ ...prevState, attachments: filtered }));

                                }}>{t.translate("Remove")}</button></td>

                              </tr>

                            </>)
                          })}

                        </tbody>

                      </table>
                    </div>
                  </div>
                </Col>



              </Row>

            </FormGroup>



            <Row className="justify-content-center" >

              <Col md={3}>

                <Input type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "blue" }} disabled={formData.selectedStudents?.length < 1 || formData.selectedStudents?.length == undefined || !formData.reason_id} value="Submit" />
              </Col>
              <Col md={3}>
                <Input className="btn btn-danger mt-3" style={{ backgroundColor: "red" }} onClick={() => { props.setModal(false); intialFormData(); }} value="Cancel" />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>

      {/* Attachment Modal  */}

      <Modal isOpen={attachModal} className="sizeModal">
        <ModalHeader>{t.translate("Add")}{" "}{t.translate("Attachment")} :</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitFileHandler}>
            <Row className="justify-content-center" >

              <Col md={12}>

                <input type="file" required onChange={(e) => {

                  setFormData((prevState: any) => ({
                    ...prevState, selectedFile: e.target.files
                  }))
                }} ></input>
              </Col>


              <Col md={3}>
                <Input type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "blue" }} value="Submit" />
                <Input className="btn btn-danger mt-3" style={{ backgroundColor: "red" }} onClick={() => setAttachModal(false)} value="Cancel" />
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

    </>
  )
}


export default ExcuseModal;