import React, { useEffect, useState } from "react";
import Select from "react-select";
import { makeId } from "../../../helpers/func";

import { url } from "../../../store/initialStatus";
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardImg,
  Button,
  CardGroup,
} from "reactstrap";
import axios from "axios";
import "./Resturant.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import { Translate } from "../../../helpers/Translate";

const Image = React.memo(function Image(props: any) {
  return (
    <img
      src={props.src}
      width={props.width}
      alt={props.alt}
      className="spinner"
    />
  );
});

function Resturant() {
  interface Idays {
    Saturday: [];
    Sunday: [];
    Monday: [];
    Tuesday: [];
    Wednesday: [];
    Thursday: [];
  }
  const t = new Translate();
  const [resturantData, setReturantData] = useState({
    student_id: [""],
    studentname: [""],
    itemname: [""],
    ItemCode: [""],
    excludingreds: [""],
    gurdian_id: 303,
  });
  const WeekDay = [
    t.translate("Sunday"),
    t.translate("Monday"),
    t.translate("Tuesday"),
    t.translate("Wednesday"),
    t.translate("Thursday"),
  ];
  //const [students, setStudents] = useState([]);
  //const [Meals, setMeals] = useState([]);

  const [StudentOptions, setStudentOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const History = useHistory();
  const [modal, setModal] = useState(false);
  const [DayMenu, SetDayMenu] = useState<any>(null);
  const [reservation, setReservation] = useState<any>({});

  const toggle = () => setModal(!modal);

  const [filteredDaysR, setFilteredDaysR] = useState<Idays>();
  const filterItemsByDayR = (items: any) => {
    setFilteredDaysR((prevState: any) => {
      return {
        Saturday: items.filter((item: any) => item.day === "Saturday"),
        Sunday: items.filter((item: any) => item.day === "Sunday"),
        Monday: items.filter((item: any) => item.day === "Monday"),
        Tuesday: items.filter((item: any) => item.day === "Tuesday"),
        Wednesday: items.filter((item: any) => item.day === "Wednesday"),
        Thursday: items.filter((item: any) => item.day === "Thursday"),
      };
    });
  };
  const [filteredDays, setFilteredDays] = useState<Idays>();
  const filterItemsByDay = (items: any) => {
    setFilteredDays((prevState: any) => {
      return {
        Saturday: items.filter((item: any) => item.day === "Saturday"),
        Sunday: items.filter((item: any) => item.day === "Sunday"),
        Monday: items.filter((item: any) => item.day === "Monday"),
        Tuesday: items.filter((item: any) => item.day === "Tuesday"),
        Wednesday: items.filter((item: any) => item.day === "Wednesday"),
        Thursday: items.filter((item: any) => item.day === "Thursday"),
      };
    });
  };
  const filterReservationByDay = (items: any) => {
    setReservation((prevState: any) => {
      return {
        Sunday: items.filter((item: any) => item.day === "Sunday"),
        Monday: items.filter((item: any) => item.day === "Monday"),
        Tuesday: items.filter((item: any) => item.day === "Tuesday"),
        Wednesday: items.filter((item: any) => item.day === "Wednesday"),
        Thursday: items.filter((item: any) => item.day === "Thursday"),
      };
    });
  };
  const [filteredDaysIng, setFilteredDaysIng] = useState<Idays>();
  const filterIngredientsByDay = (items: any) => {
    setFilteredDaysIng((prevState: any) => {
      return {
        Saturday: items.filter((item: any) => item.day === "Saturday"),
        Sunday: items.filter((item: any) => item.day === "Sunday"),
        Monday: items.filter((item: any) => item.day === "Monday"),
        Tuesday: items.filter((item: any) => item.day === "Tuesday"),
        Wednesday: items.filter((item: any) => item.day === "Wednesday"),
        Thursday: items.filter((item: any) => item.day === "Thursday"),
      };
    });
  };
  const [mealsOptions, setMealsOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const sendGurdianId = (resturantData: any) => {
    axios
      .get(`${url}reservemeals/getutils.php`)
      .then((res: any) => {
        filterItemsByDay(res.data.data.items);

        const newStudentOptions = res.data.data.students.map((student: any) => {
          return {
            label: ` ${student.fullname} `,
            value: student.id,
          };
        });

        setStudentOptions(newStudentOptions);

        if (res.data.data.reservations) {
          filterReservationByDay(res.data.data.reservations);
        } else {
          const newMealOptions = res.data.data.items.map((meal: any) => {
            return {
              ...meal,
              label: `${meal.ItemCode ? meal.ItemCode : " "} - ${
                meal.ItemAname
              }`,
              value: meal.ItemCode,
            };
          });
          setMealsOptions(newMealOptions);
        }
      })
      .catch(() => {
        Swal.fire(
          t.translate(
            "You can make your weekly menu reservations  from Monday till Wednesday of every week"
          ),
          " ",
          "error"
        ).then(() => {
          History.push("dashboard/home");
        });
      });
  };

  useEffect(() => {
    (async () => {
      sendGurdianId(resturantData);
    })();
  }, []);
  const Submit = () => {
    Swal.showLoading();
    axios
      .post(`${url}reservemeals/savemenu.php`, reservation)
      .then((res: any) => {
        console.log(reservation);
        console.log(res);
        if (res.data.data === "success") {
          Swal.fire("Saved Successfully!", "", "success");
        } else {
          Swal.fire(
            "Something go wrong please try again later !!",
            "",
            "error"
          );
        }

        // const newStudentOptions = res.data.data.students.map((student: any) => {
        //     return {
        //         label: ` ${student.fullname} `,
        //         value: student.id
        //     };
        // })

        // setStudentOptions(newStudentOptions);
        // const newMealOptions = res.data.data.items.map((meal: any) => {
        //     return {...meal,
        //         label: `${meal.ItemCode ? meal.ItemCode : " "} - ${meal.ItemAname}`,
        //         value: meal.ItemCode
        //     };
        // });

        // setMealsOptions(newMealOptions);
        // //setMeals(res.data.data);
      });
  };
  const onStudentSelect = (selectedOption: any) => {
    if (selectedOption) {
      setReturantData((prevState) => ({
        ...prevState,
        student_id: selectedOption.value,
      }));
    } else {
      setReturantData((prevState: any) => ({ ...prevState, student_id: "" }));
    }
  };

  const getIngredients = (itemsIds: any) => {
    axios
      .post(`${url}reservemeals/getingredients.php`, {
        itemsIds: itemsIds,
      })
      .then((res) => {
        // const newingredientOptions = res.data.data.map((meal: any) => {
        // return {
        //     label: `${meal.ingredientname}`,
        //     value: meal.ItemStockCode
        // };
        // });
        //setingredientOptions(newingredientOptions);
      });
  };

  const Clear = (day: any, i: any) => {
    if (!reservation[day] || i == -1) {
      return;
    } else {
      setReservation((prevState: any) => {
        let dayReservation = prevState[day];
        console.log(dayReservation);
        let obj = dayReservation.splice(i, 1);
        console.log(dayReservation);
        getIngredients(
          dayReservation.map((d: any) => {
            if (d.student_id == obj.student_id) return d.ItemCode;
          })
        );
        prevState[day] = dayReservation;
        return { ...prevState };
      });
    }
  };

  const genModalMenu = (dayMenu: any, student_id: string, day: string) => {
    let m = dayMenu.map((item: any) => {
      let classN = "";

      if (reservation[day]) {
        let temp = reservation[day].find(
          (i: any) =>
            i.student_id === student_id && i.itemcode === item.ItemCode
        );
        if (temp) {
          classN = " activeCard ";
        }
      }
      return (
        <React.Fragment key={makeId(10)}>
          <input
            type="checkbox"
            id={item.ItemCode}
            onChange={(e) => {
              console.log(e);
            }}
          />
          <Card
            className={" pointer " + classN}
            style={{ minWidth: "300px", maxWidth: "400px", margin: "5px" }}
            onClick={() => {
              let tempR = reservation;

              if (!tempR[day]) {
                tempR[day] = [
                  { student_id: student_id, itemcode: item.ItemCode },
                ];
              } else {
                let x = tempR[day].findIndex(
                  (i: any) =>
                    i.student_id === student_id && i.itemcode === item.ItemCode
                );
                if (x !== -1) {
                  tempR[day].splice(x, 1);
                } else {
                  tempR[day].push({
                    student_id: student_id,
                    itemcode: item.ItemCode,
                  });
                }
              }
              setReservation(tempR);
              genModalMenu(dayMenu, student_id, day);
            }}
          >
            <Image
              src={
                item.imgurl == ""
                  ? "https://i.pinimg.com/originals/dd/9d/c9/dd9dc9d83423bc037b511d73b29e6b80.png"
                  :  item.imgurl?.includes("https://") ? item.imgurl : `https://drive.google.com/uc?id=${item.imgurl}&export=download`
              }
              alt={item.ItemEname}
              width="100%"
            />
            <CardBody>
              <CardTitle tag="h5">{item.ItemEname}</CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">
                {item.ItemAname}
              </CardSubtitle>
              <CardText>
                {t.translate("Price")}: {item.SellingPrice}{" "}
                {t.translate("L.E.")}
              </CardText>
            </CardBody>
          </Card>
        </React.Fragment>
      );
    });
    SetDayMenu(m);
  };

  var Sunday = new Date();
  Sunday.setDate(Sunday.getDate() + ((5 + (7 - Sunday.getDay())) % 7) + 2);
  var Monday = new Date();
  Monday.setDate(Monday.getDate() + ((5 + (7 - Monday.getDay())) % 7) + 3);
  var Tuesday = new Date();
  Tuesday.setDate(Tuesday.getDate() + ((5 + (7 - Tuesday.getDay())) % 7) + 4);
  var Wednesday = new Date();
  Wednesday.setDate(
    Wednesday.getDate() + ((5 + (7 - Wednesday.getDay())) % 7) + 5
  );
  var Thursday = new Date();
  Thursday.setDate(
    Thursday.getDate() + ((5 + (7 - Thursday.getDay())) % 7) + 6
  );

  return (
    <React.Fragment>
      <div className="mb-3 font-weight-bold font-weight-lighter">
        {`" ${t.translate("This selection is valid from")} ${t.translate(
          "Sunday"
        )} ${Sunday.toLocaleString().split(",")[0]} ${t.translate(
          "to"
        )} ${t.translate("Thursday")} ${
          Thursday.toLocaleString().split(",")[0]
        } " `}{" "}
      </div>
      <div className="mb-3 font-weight-bold font-weight-lighter"> </div>
      <Modal isOpen={modal} toggle={toggle} className="modal-xl">
        <ModalHeader toggle={toggle}>{t.translate("Meals")}</ModalHeader>
        <ModalBody>
          <CardGroup>{DayMenu}</CardGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggle();
            }}
          >
            {t.translate("Done")}
          </Button>
        </ModalFooter>
      </Modal>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <td>
                <h4>{t.translate("Days")}</h4>
              </td>
              <td>
                <h4>{t.translate("Students")}</h4>
              </td>
              <td>
                <h4>{t.translate("Meals")}</h4>
              </td>
            </tr>
          </thead>
          <tbody>
            {WeekDay.map((day) => {
              let temp = StudentOptions
                ? StudentOptions.map((options, i) => {
                    let DaysOptions: any = filteredDays ?? [];

                    if (i == 0) {
                      return (
                        <tr key={day + options.label}>
                          <td rowSpan={StudentOptions.length}>
                            <h5>{day}</h5>
                          </td>
                          <td
                            style={{ margin: "10px" }}
                          >{`${options.label}: ${options.value}`}</td>
                          <td>
                            {DaysOptions[day].map((Meal: any, i: any) => {
                              let found = false;
                              var index = -1;
                              if (reservation[day]) {
                                index = reservation[day].findIndex(
                                  (i: any) =>
                                    i.student_id === options.value &&
                                    i.itemcode === Meal.ItemCode
                                );
                                if (index !== -1) {
                                  found = true;
                                }
                              }
                              if (found)
                                return (
                                  <label
                                    key={i + Meal.ItemCode}
                                    style={{ margin: "3px", padding: "5px" }}
                                    className="badge badge-primary badge-pill"
                                  >
                                    <button
                                      type="button"
                                      className="close"
                                      style={{
                                        marginTop: "-4px",
                                        marginLeft: "5px",
                                      }}
                                      aria-label="Close"
                                    >
                                      {" "}
                                      <span
                                        onClick={() => Clear(day, index)}
                                        aria-hidden="true"
                                      >
                                        &times;{" "}
                                      </span>
                                    </button>
                                    <span>{`${Meal.ItemEname}`}</span>
                                  </label>
                                );
                            })}
                            <div></div>
                            <button
                              className="btn btn-lg btn-success"
                              onClick={() => {
                                genModalMenu(
                                  DaysOptions[day],
                                  options.value,
                                  day
                                );
                                toggle();
                              }}
                            >
                              {t.translate("Select Meals")}
                            </button>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={day + options.label}>
                          <td
                            style={{ margin: "10px" }}
                          >{`${options.label}- ${options.value}`}</td>
                          <td>
                            {DaysOptions[day].map((Meal: any, i: any) => {
                              let found = false;
                              let index = -1;
                              if (reservation[day]) {
                                index = reservation[day].findIndex(
                                  (i: any) =>
                                    i.student_id === options.value &&
                                    i.itemcode === Meal.ItemCode
                                );
                                if (index !== -1) {
                                  found = true;
                                }
                              }
                              if (found)
                                return (
                                  <label
                                    key={i + Meal.ItemCode}
                                    style={{ margin: "3px", padding: "5px" }}
                                    className="badge badge-primary badge-pill"
                                  >
                                    <button
                                      type="button"
                                      className="close"
                                      style={{
                                        marginTop: "-4px",
                                        marginLeft: "5px",
                                      }}
                                      aria-label="Close"
                                    >
                                      {" "}
                                      <span
                                        onClick={() => Clear(day, index)}
                                        aria-hidden="true"
                                      >
                                        &times;{" "}
                                      </span>
                                    </button>
                                    <span>{`${Meal.ItemEname}`}</span>
                                  </label>
                                );
                            })}
                            <div></div>
                            <button
                              className="btn btn-lg btn-success"
                              onClick={() => {
                                genModalMenu(
                                  DaysOptions[day],
                                  options.value,
                                  day
                                );
                                toggle();
                              }}
                            >
                              {t.translate("Select Meals")}
                            </button>
                          </td>
                          {/* <td style={{ width: "300px" }}>
                                                    <Select isMulti options={ingredientOptions} onChange={onStudentSelect} placeholder="Select Ingredients" />

                                                </td> */}
                        </tr>
                      );
                    }
                  })
                : null;

              return temp;
            })}
          </tbody>
        </table>
      </div>
      <button className="btn btn-warning btn-lg w-100" onClick={Submit}>
        {t.translate("Save")} !!
      </button>
    </React.Fragment>
  );
}

export default Resturant;
