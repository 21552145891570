import axios from "axios";
import { url } from "../store/initialStatus";

export class Translate {
    static lang = "EN"
    storedDictionary: any = [];
    preferred_language = "en"

    constructor() {
        let localDistionary = localStorage.getItem('parent_dict');
        let valid = false;
        try {
            if (localDistionary){
                this.storedDictionary = JSON.parse(localDistionary)
                valid = true;
            }
        } catch (error) {
            
        }
        if (!localDistionary || !valid) {
            axios.get(url + 'translation/getdictionary.php').then((res) => {
            
                if(res?.data?.status === "success"){
                    localStorage.setItem("parent_dict", res?.data?.data?.data)
                    //window.location.reload()
                }
            })
        } else {
            
        }

        let stored_preferred_lang = localStorage.getItem('preferred_lang');
        if (!stored_preferred_lang) {
            this.preferred_language = 'en'
        } else {
            this.preferred_language = stored_preferred_lang
        }
    }

    translateAuto() {

        setTimeout(() => {
            window.requestAnimationFrame(() => {

                // let allelements = document.querySelectorAll('*')
                let allelements = document.getElementsByTagName('a')
                for (let i = 0, max = allelements.length; i < max; i++) {
                    // Do something with the element here
                    let element = allelements[i]
                    let index = this.storedDictionary.findIndex((w: any) => w.word === element.textContent)
                    console.log(element.textContent, 'auto auto');

                    if (index !== -1 && this.preferred_language === "en") {
                        element.textContent = this.storedDictionary[index].EN
                    } else if (index !== -1 && this.preferred_language === "ar") {
                        element.textContent = this.storedDictionary[index].AR

                    } else {
                        return element.textContent;
                    }
                }


            })
        })
        // allelements.forEach((element: any) => {
        //     let index = this.storedDictionary.findIndex((w: any) => w.word === element.textContent)
        //     console.log(element.textContent);

        //     if (index !== -1 && this.preferred_language === "en") {
        //         element.textContent = this.storedDictionary[index].EN
        //     } else if (index !== -1 && this.preferred_language === "ar") {
        //         element.textContent = this.storedDictionary[index].AR

        //     } else {
        //         return element.textContent;
        //     }
        // })
    }


    translate(word: any) {

        let index = this.storedDictionary.findIndex((w: any) => w.keyword === word)
        if (index !== -1 && this.preferred_language === "en") {
            return this.storedDictionary[index].en
        } else if (index !== -1 && this.preferred_language === "ar") {
            return this.storedDictionary[index].ar
        } else {
           // axios.post(url + 'translation/addword.php', { keyword: word })
            return word;
        }
    }
}