import React from "react";
import { NavLink } from "react-router-dom";
import Notifications from "../MyComponents/NotificationsComponent";
import { Translate } from "../../helpers/Translate";
import { Navbar, NavItem, DropdownMenu, DropdownItem } from "reactstrap";

import ProfileMenu from "../Parent/parentpages/ProfileMenu";
import ParentMenu from "../Parent/parentpages/ParentMenu";

function DashBoardNav(props: any) {
  //
  let t = new Translate();
  return (
    <>
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        &nbsp;&nbsp;&nbsp;
        <button
          className="btn btn-link btn-sm  float-sm-left"
          id="sidebarToggle"
          onClick={props.toggleSideBar}
        >
          <i className="fa fa-bars"></i>
        </button>
        <NavLink className="navbar-brand nav-link" to="/home">
          {" "}
        </NavLink>
        {/* <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
      <div className="input-group">
        <input className="form-control" type="text" placeholder="Search for..." aria-label="Search"
          aria-describedby="basic-addon2" />
        <div className="input-group-append">
          <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button>
        </div>
      </div>
    </form><Notifications /> */}
        <ParentMenu />
      </nav>
    </>
  );
}

export default DashBoardNav;
