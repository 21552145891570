import React, { Component } from "react";
import Loader from "react-loader-spinner";

import ivy_logo from '../../../assets/ivy_logo.png';
import harmony_logo from '../../../assets/harmonylogo.svg';



import { actionCreators as ParentCreator } from "../../../store/Parent";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";

const mapDispatchToProps = (dispatch: (arg0: any) => any) => ({
  ChangePassword: (data: any) =>
        dispatch(ParentCreator.ChangePassword(data)),
        ClearParent: (data: any) =>
        dispatch(ParentCreator.ClearParent(data)),
   

});

class ChangePass extends Component<any,any> {
    constructor(props:any){
        super(props)
        this.state = {
            currentpassword :"" ,
            newpassword :"",
            confirmnewpassword :"",
            jwt :localStorage.token
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    onSubmit (e:any){
        e.preventDefault()
      this.props.ChangePassword(this.state)
      // this.props.history.push("/login")
    }
    onCancel(){
      this.props.ClearParent()
      this.props.history.push("/login")
         }
render(){
  let error = null ;
  if(this.props.error){
      error =  <SweetAlert
      danger
      title="Warning"
      onConfirm={this.onCancel}
      onCancel={this.onCancel}
    >
      {this.props.error}
    </SweetAlert>
  }

  let forSuccess = null ;
  if(this.props.updateMsg){
     forSuccess = (
         <SweetAlert
           success
           title="Success"
           onConfirm={this.onCancel}
           onCancel={this.onCancel}
         >
           {this.props.updateMsg}
         </SweetAlert>
      ) }

    return (
        <div className='container '>
         {error}
         {forSuccess}
          <div
            className='row align-items-center justify-content-center'
            style={{ height: '100vh' }}
          >
            {!this.props.updateGuardianLoading ? (
              <div className='col-xl-10 col-lg-12 col-md-9'>
                <div className='card o-hidden border-0 shadow-lg '>
                  <div className='card-body p-0'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <div className='p-5 bg-dark'>
                          <div className='col-12 row justify-content-center align-items-center align-content-center '>
                            <div className='col-12 row justify-content-center align-items-center mt-3'>
                              <img
                                src={ivy_logo}
                                height='150px'
                                width='150px'
                                className='rounded d-block '
                                style={{ width: 'auto' }}
                                alt='...'
                              />
                            </div>
                            <div className='col-12 row justify-content-center align-items-center mt-3'>
                              <img
                                src={harmony_logo}
                                height='40px'
                                className='rounded d-block'
                                style={{ width: 'auto' }}
                                alt='...'
                              />
                            </div>
                            <div className='col-12 mt-4  text-center'>
                              <h2
                                className='text-white'
                                style={{
                                  textShadow:
                                    '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                                  fontSize: '1.2rem',
                                }}
                              >
                               
                              </h2>
                            </div>
                          </div>
                          <form className='col-12'>
                            <div className='form-group row  justify-content-center mt-2'>
                              <div className='col-md-6 col-xl-5'>
                              <input
                                  id='changepass'                                
                                  value={this.state.currentpassword}
                                  onChange={(e:any) =>{this.setState({currentpassword : e.target.value})}}
                                  required={true}
                                  maxLength={100}
                                  type="text"
                                  className='form-control form-control-lg border-0 text-black'
                                  placeholder='Current Password'                                  
                                />

                                <br/>
                                <input
                                  id='newpass'                                
                                  value={this.state.newpassword}
                                  onChange={(e:any) =>{this.setState({newpassword : e.target.value})}}
                                  required={true}
                                  maxLength={100}
                                  type="text"
                                  className='form-control form-control-lg border-0 text-black'
                                  placeholder='New Password'                                  
                                />

                                <br/>

                                <input
                               id='newpass2'                                
                               value={this.state.confirmnewpassword}
                               onChange={(e:any) =>{this.setState({confirmnewpassword : e.target.value})}}
                               required={true}
                               maxLength={100}
                               type="text"
                               className='form-control form-control-lg border-0 text-black'
                               placeholder='New Password'
                             />
                              </div>
                              
                            </div>
                           
                            <div className='form-group row  justify-content-center mt-5'>
                              <div className='col-md-6 col-xl-5'>

                                <button
                                  type='submit'
                                  className={`btn btn-secondary  btn-block btn-warning text-white `}
                                  onClick={this.onSubmit}                                 
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='d-flex justify-content-center align-items-center mt-5'>
                <Loader type='ThreeDots' color='#00BFFF' height={100} width={100} />
              </div>
            )}
          </div>
        </div>
      );
}
}


function mapStateToProps(state: any) {
    return {
      updateGuardianLoading: state.Parent.updateGuardianLoading,
      updateMsg: state.Parent.updateMsg,
      error: state.Parent.error,
        
      

    };
}

export default withRouter(
    connect(
        mapStateToProps, // Selects which state properties are merged into the component's props
        mapDispatchToProps // Selects which action creators are merged into the component's props
    )(ChangePass as any)
);
