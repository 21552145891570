import React, { useEffect } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import Login from "./components/Account/Loginc";
import DashBoard from "./components/DashBoard/DashBoardComponent";
import { ToastProvider } from "react-toast-notifications";
import { actionCreators as ParentCreators } from "./store/Parent";
import Cookies from "js-cookie";
import { Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenerateCode from "./components/Parent/parentpages/GenerateCode";
import CheckCode from "./components/Parent/parentpages/CheckCode";
import NewPass from "./components/Parent/parentpages/NewPass";
import setAuthToken from "./helpers/setAuthToken";
import Meetings from "./components/Students/Meetings/Meetings";
import Swal from "sweetalert2";
import { parseJwt } from "./helpers/func";
import { setTokenExpiration } from "./utils/auth-refresh";
function App() {
  const dispatch: Dispatch<any> = useDispatch();
  const jwt: string | null = useSelector((state: any) => state.Parent.jwt);
  const dictionary_fetched:any = useSelector((state: any) => state.Parent.dictionary_fetched);
  const token = Cookies.get("guardiantoken");
  //console.log(jwt);
  useEffect(() => {
    try {
      if (token && !jwt) {
       
        dispatch(ParentCreators.LoginIframe());
        dispatch(ParentCreators.FetchDictionary());
        setAuthToken(token);
        setTokenExpiration( parseJwt(token))
      } else {
        
        if(token){

          setTokenExpiration(parseJwt(token))
        }
        setAuthToken(jwt);
        dispatch(ParentCreators.FetchDictionary());
      }
    } catch (e) {
      let ee = e as any;
      Swal.fire(ee?.response?.data.msg || "Failed to login", "", "error");
    }
  }, []);

  let verified = null;
  if (token ) {
    setTokenExpiration(parseJwt(token))
    verified = (
      <DashBoard />
      /*  <Switch>
        <Route path="/sidnav" component={SideNav} />
        <Route path="/dashboard" component={DashBoard} />
        <Redirect to="/dashboard/home" />
    </Switch> */
    );
  } else {
    verified = (
      <Switch>
        <Route exact path="/dashboard/meetings" component={() => <Meetings />} ></Route>
        <Route exact path="/GenerateCode" component={() => <GenerateCode />}></Route>
        <Route exact path="/CheckCode" component={() => <CheckCode />}></Route>
        <Route exact path="/newpass" component={() => <NewPass />}></Route>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    );
  }
  return (
    <React.Fragment>
      {
        dictionary_fetched === true?<ToastProvider>{verified}</ToastProvider>:null
      }
      
    </React.Fragment>
  );
}

export default withRouter(App);
