import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionCreators as StudnetCreator } from "../../store/student";
import { MDBDataTableV5 } from 'mdbreact';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Line from './Chart/Line';



export default function ReinforcementsDetails() {
  const dispatch: Dispatch<any> = useDispatch();
  const { id } = useParams<{id?: string}>();
  const students = useSelector((state: any) => state.Student.students);
  const student = students.find((st:any) => st.id == id);
  const guardian = useSelector((state: any) => state.Parent.guardian),
        [reinforcementsData, setReinforcementsData] = useState({
            columns: [
                {
                    label: "Reinforcement point ",
                    field: "pointname"
                },
                {
                    label: "Points ",
                    field: "points"
                },
                {
                    label: "Teacher name",
                    field: "displayname"
                },
                {
                    label: "Date",
                    field: "date"
                },
               
            ],
            rows: [
                {
                    pointname: <></>,
                    points: <></>,
                    displayname: <></>,
                    date: <></>,
                  
                }
            ]
        })
  useEffect(() => {
      console.log(students);
    dispatch(StudnetCreator.GetStudents({ id: guardian.id }));

    setReinforcementsData((old:any)=>{
        return ({...old,rows:student.Reinforcements}) ;   
    });
  
  }, []);


  return (
    <div className="row">
        <div className="text-primary">
            {(student) ? <h3 className="mb-5">{student.fullname} : <small style={{fontStyle:'italic'}}>{student.id} : {student.grade}</small></h3> : <></>}
        </div>
        <Card className="w-100">
            <CardHeader>
                <h4 className="title mb-5">Reinforcement points</h4>
                <Line color="blue" Reinforcements = {student.Reinforcements} />
            </CardHeader>
            <CardBody className="all-icons">
                <MDBDataTableV5 
                    hover
                    responsive
                    bordered
                    striped
                    data={reinforcementsData} 
                    paging={false}
                    searching={false}
                />
            </CardBody>
        </Card>
    </div>


  );
}