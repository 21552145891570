import React, { useEffect, useState, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useSelector } from "react-redux";
import Happy from "../../../assets/moodicons/Happy.png";
import Angry from "../../../assets/moodicons/Angry.png";
import Crying from "../../../assets/moodicons/Crying.png";
import Excited from "../../../assets/moodicons/Excited.png";
import Sad from "../../../assets/moodicons/Sad.png";
import Smile from "../../../assets/moodicons/Smile.png";
import Stress from "../../../assets/moodicons/Stress.png";
import Surprise from "../../../assets/moodicons/Surprise.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Form,
  Input,
  FormGroup,
} from "reactstrap";
import {
  faCoffee,
  faSchool,
  faBus,
  faSmile,
  faToilet,
  faPizzaSlice,
  faSpaceShuttle,
} from "@fortawesome/free-solid-svg-icons";
import { Translate } from "../../../helpers/Translate";

interface IFormData {
  id?: string;
  parent?: string;
  students?: Array<string>;
  reason?: string;
  attachments: Array<any>;
  student_id?: string;
  parentSign: string;
  datetime: string;
  relativename: string;
  nationalId: string;
}
const sectionContent = (html: any) => {
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
};

const Timeline= (props: any) => {
  let t = new Translate();
  const [modal, setModal] = useState(false);
  const [driveid, setDriveId] = useState(" ");
  return (
    <>
      {props.activities?.length > 0 ? (
        <VerticalTimeline lineColor="rgb(33, 150, 243)">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(16, 204, 82)" }}
            date={props.entryandleavetime?.entrytime}
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faSchool} />}
          >
            <h3 className="vertical-timeline-element-title">
              {t.translate("Arrived")}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              {props.entryandleavetime?.entrytime?.split(" ")[1]}
            </h4>{" "}
          </VerticalTimelineElement>
          {props.activities?.map((activity: any, index: any) => {
            if (activity.type_id === "3") {
              return (
                <VerticalTimelineElement
                  key={index}
                  className="vertical-timeline-element--work"
                  date={activity?.recorded_at?.split(" ")[1]}
                  iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                  icon={<FontAwesomeIcon icon={faSpaceShuttle} />}
                >
                  <h3 className="vertical-timeline-element-title">
                    {activity?.name}
                  </h3>

                  {sectionContent(activity?.description)}

                  <img
                    src={
                      activity?.mood_id === "1"
                        ? Happy
                        : activity?.mood_id === "2"
                        ? Sad
                        : activity?.mood_id === "3"
                        ? Surprise
                        : activity?.mood_id === "4"
                        ? Angry
                        : activity?.mood_id === "5"
                        ? Excited
                        : activity?.mood_id === "6"
                        ? Stress
                        : Crying
                    }
                    alt=""
                    style={{
                      maxWidth: "100%",
                      width: "100px",
                    }}
                  />
                  <strong>
                    <p style={{ marginLeft: "5%" }}>
                      {
                        props.moods?.find((f: any) => f.id === activity.mood_id)
                          ?.name
                      }
                    </p>
                  </strong>
                  {activity.driveid ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setModal(true);
                        setDriveId(activity.driveid);
                      }}
                    >
                      {t.translate("View Student's Picture")}
                    </button>
                  ) : null}
                </VerticalTimelineElement>
              );
            } else if (activity.type_id === "2") {
              return (
                <VerticalTimelineElement
                  key={index}
                  className="vertical-timeline-element--work"
                  iconStyle={{
                    background: "rgb(119,136,153)",
                    color: "#fff",
                  }}
                  date={activity?.recorded_at?.split(" ")[1]}
                  icon={<FontAwesomeIcon icon={faToilet} />}
                >
                  <h3 className="vertical-timeline-element-title">
                    {t.translate(`${activity?.name}`)}
                  </h3>
                  {sectionContent(activity?.description)}
                  <img
                    src={
                      activity?.mood_id === "1"
                        ? Happy
                        : activity?.mood_id === "2"
                        ? Sad
                        : activity?.mood_id === "3"
                        ? Surprise
                        : activity?.mood_id === "4"
                        ? Angry
                        : activity?.mood_id === "5"
                        ? Excited
                        : activity?.mood_id === "6"
                        ? Stress
                        : Crying
                    }
                    alt=""
                    style={{ maxWidth: "100%", width: "100px" }}
                  />{" "}
                  <strong>
                    <p style={{ marginLeft: "5%" }}>
                      {
                        props.moods?.find((f: any) => f.id === activity.mood_id)
                          ?.name
                      }
                    </p>
                  </strong>
                  {activity.driveid ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setModal(true);
                        setDriveId(activity.driveid);
                      }}
                    >
                      {t.translate("View Student's Picture")}
                    </button>
                  ) : null}
                </VerticalTimelineElement>
              );
            } else {
              return (
                <VerticalTimelineElement
                  key={index}
                  className="vertical-timeline-element--work"
                  date={activity?.recorded_at?.split(" ")[1]}
                  iconStyle={{
                    background: "rgb(220,20,60)",
                    color: "#fff",
                  }}
                  icon={<FontAwesomeIcon icon={faPizzaSlice} />}
                >
                  <h3 className="vertical-timeline-element-title">
                    {activity?.name}
                  </h3>
                  {sectionContent(activity?.description)}
                  <img
                    src={
                      activity?.mood_id === "1"
                        ? Happy
                        : activity?.mood_id === "2"
                        ? Sad
                        : activity?.mood_id === "3"
                        ? Surprise
                        : activity?.mood_id === "4"
                        ? Angry
                        : activity?.mood_id === "5"
                        ? Excited
                        : activity?.mood_id === "6"
                        ? Stress
                        : Crying
                    }
                    alt=""
                    style={{
                      maxWidth: "100%",
                      width: "100px",
                    }}
                  />{" "}
                  <strong>
                    <p style={{ marginLeft: "5%" }}>
                      {
                        props.moods?.find((f: any) => f.id === activity.mood_id)
                          ?.name
                      }
                    </p>
                  </strong>
                  {activity.driveid ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setModal(true);
                        setDriveId(activity.driveid);
                      }}
                    >
                      {t.translate("View Student's Picture")}
                    </button>
                  ) : null}
                </VerticalTimelineElement>
              );
            }
          })}

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(233, 30, 99)" }}
            date={props.entryandleavetime?.entrytime}
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<FontAwesomeIcon icon={faBus} />}
          >
            <h3 className="vertical-timeline-element-title">
              {t.translate("Departed")}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              {props.entryandleavetime?.leavetime?.split(" ")[1]}
            </h4>{" "}
          </VerticalTimelineElement>
        </VerticalTimeline>
      ) : null}
      <Modal className="sizeModal" isOpen={modal}>
        <ModalHeader>{t.translate("View Student's Picture")}</ModalHeader>
        <ModalBody></ModalBody>
        <ModalFooter>
          <img
            className="d-inline-block responsive "
            style={{ border: "2px solid" }}
            src={ driveid?.includes("https://")?driveid :`https://drive.google.com/uc?id=${driveid}&export=download`}
            alt="user-image"
          />
          <button className="btn btn-secondary" onClick={() => setModal(false)}>
            {t.translate("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Timeline;
