import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Initial from './initialStatus';
import { SET_USER_LOGIN, CLEAR_USER_LOGOUT} from './ActionTypes';
import { getCookie,Request } from '../helpers/func';
import { isNullOrUndefined } from '../helpers/func';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {

    token:string, ApiKey: string, UserId: 0, UserName: string, PasswordHash: string,
    Name: string, Email: string, EmailConfirmed: boolean, PhoneNumber: string, RoleID: number,MenuItem:Array<any>,
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SET_USER_LOGIN { type:string,user:UserState }
export interface CLEAR_USER_LOGOUT { type: string,user:UserState }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SET_USER_LOGIN | CLEAR_USER_LOGOUT | any;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    SET_USER_LOGIN: (user: UserState):AppThunkAction < KnownAction | any> => async (dispatch, getState) => { 
    },
    SET_USER_MENU: (Menu: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        let user = { ...appState.user, MenuItem: Menu }
        dispatch({ type: SET_USER_LOGIN, user: user });
    },
    CLEAR_USER_LOGOUT: (): AppThunkAction<KnownAction> => async (dispatch, getState) => { 
        let state = getState();
        if (isNullOrUndefined(state.user) || state.user?.UserId === 0) {
            dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState });
         }
        else {
           /*  Request(Initial.url + "/logout", "POST", {}, state.user?.token)
                .then((x) =>
            {    */                
                dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState });

           /*  }).catch((err) => { */
               //   
                dispatch({ type: CLEAR_USER_LOGOUT, user: Initial.UserState });
            /* }); */
            //window.location.reload(false);
        }
    },
    GET_USER: (): AppThunkAction<KnownAction> => async (dispatch, getState) => { 
        let x = getCookie('ucookie');
        if (!isNullOrUndefined(x) && x !== "") {
            Request(Initial.url + '/api/getuser', "POST", x, "")
                .then((response) => {
                    let jdata = response.json;
                    if (response.status === 200) {
                        jdata.Sid = x;
                        dispatch({ type: SET_USER_LOGIN, user: jdata })
                        return true;
                    } else {
                        //dispatch({type:CLEAR_USER_LOGOUT,user:Initial.UserState})
                        return false;
                    }
            })    
        }
        return false;
    },
};


export const reducer: Reducer<UserState|any> = (state: UserState | undefined, incomingAction: Action): UserState|any => {
    if (state === undefined) {
        return Initial.UserState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case SET_USER_LOGIN:
            return action.user;
        case CLEAR_USER_LOGOUT:
            return Initial.UserState ;
        default:
            return state;
    }
};
