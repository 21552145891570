import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import App from "./App";

//STEP 1:
//create components using React.lazy
const EnglishTheme = React.lazy(() => import("./Themes/ThemeEn"));
const ArabicTheme = React.lazy(() => import("./Themes/ThemeAr"));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }: any) => {
  const CHOSEN_THEME = localStorage.getItem("preferred_lang") || "en";
  return (
    <>
      <React.Suspense fallback={<></>}>
        {CHOSEN_THEME === "en" && <EnglishTheme />}
        {CHOSEN_THEME === "ar" && <ArabicTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

// Create browser history to use in the Redux store
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: "./" });
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeSelector>
        <div>
          <App />
        </div>
      </ThemeSelector>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

//registerServiceWorker();
