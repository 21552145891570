import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { url } from '../../../../store/initialStatus';
import MedicalRecord from './MedicalRecord'
import Chromebook from './ChromeBook';
import { Row, Col, Card, CardImg, CardBody, CardTitle, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import PanelHeader from '../PanelHeader/PanelHeader';
import "./Home.css";
import { Translate } from '../../../../helpers/Translate';
import axios from 'axios';
import Swal from 'sweetalert2';

//const img1 = require("./assets/img/schoology_logo.png").default;
const img12 = require("./assets/img/menu.png").default;
const img2 = require("./assets/img/ivy_transportation.png").default;

const schoologyimg = require("./assets/img/schoology_logo.7b8cbed4.png").default;
const wallet = require("./assets/img/wallet.png").default;

const t = new Translate();




const Home = () => {

  const height = "100Px";
  const transjwt: any = useSelector(
    (state: any) => state.Parent.transjwt
  );
  const schoologyUserName: any = useSelector(
    (state: any) => state.Parent.schoologyUserName
  );
  const schoologyPass: any = useSelector(
    (state: any) => state.Parent.schoologyPass
  );



  const setLms = ()=>{
   
    axios.post(`${process.env.REACT_APP_LMS_BASURL}include/store_parent_token.php`,
    {

      username: schoologyUserName,
      emaildomain: "ivyis.org"
    }).then((res) => {

     
      const url ="https://lms.ivyis.org";
      window.open(url);
     
    }).catch((err) => {
      console.log(err)
      Swal.fire(
        t.translate("An Error Occurred"),
        "Something wrong happened!",
        "error"
      );
    })
  }
  return (
    <>

      <MedicalRecord />

      <Chromebook />
      <PanelHeader size="sm" />
      <div className="content">

      

        <Row>

          <Col lg={2} md={3} sm={4} xs={6}>
            <a className="" style={{cursor: "pointer"}} onClick={()=>{setLms()}}>
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={"https://staff.ivyis.org/images/ivylms.jpg"} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Learning management system")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <NavLink to={"/dashboard/cafeteria"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img12} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Cafeteria")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href={`https://transportation.ivyis.org/?hash=` + transjwt} target="_blank" className="">
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={img2} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Transportation")}
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>

        


          <Col lg={2} md={3} sm={4} xs={6}>
            <NavLink to={"/dashboard/ewallet"} >
              <Card className="frame text-center p-2">
                <br />
                <CardImg top height={height} src={wallet} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    {t.translate("Smart wallet")}
                  </CardTitle>
                </CardBody>
              </Card>
            </NavLink>
          </Col>





          {/*
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://test.mapnwea.org/" target="_blank" className="">
              <Card className="frame text-center">
              <br/>
                <CardImg top   src={img3} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    nwea map
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>


          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://www.office.com/launch/excel" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  height={"60%"} src={img4} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Excel
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://teams.microsoft.com/" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  className="col-md-6" src={img5} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Microsoft teams
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://www.office.com/launch/word" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  className="col-md-6" src={img6} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Word
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://outlook.office.com/mail/inbox" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top className="col-md-6" src={img7} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Outlook
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://www.office.com/launch/powerpoint" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  className="col-md-6" src={img8} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    PowerPoint
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://ivyis-my.sharepoint.com" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  className="col-md-6" src={img9} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    OneDrive
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://to-do.office.com/tasks/myday" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  className="col-md-6" src={img10} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                 <br/>   To Do
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://app.whiteboard.microsoft.com/me/whiteboards/" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  className="col-md-6" src={img11} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Whiteboard
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://tasks.office.com/ivyis.org/en-GB/Home/Planner/" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top height={"240%"} className="col-md-6" src={require("./assets/officeimages/PNG/128x128/planner_144.png").default} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Planner
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>

          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://drive.google.com/drive" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  src={require("./assets/googleImages/Google-Drive.ico").default} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Google drive
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>

          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://docs.google.com/presentation/" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  src={require("./assets/googleImages/google-slides.png").default} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Google slides
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>

          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://classroom.google.com/" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg  top  src={require("./assets/googleImages/google-classroom.png").default} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="text-left">
                   Classroom
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>

          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://docs.google.com/spreadsheets/" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  src={require("./assets/googleImages/google-sheet.ico").default} alt="Card image cap" />
                <CardBody>
                  <CardTitle>
                    Google sheet
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>

          <Col lg={2} md={3} sm={4} xs={6}>
            <a href="https://meet.google.com/" target="_blank" className="">

              <Card className="frame text-center">
                <CardImg top  src={require("./assets/googleImages/google-meet.png").default} alt="Card image cap" />
                <CardBody>
                  <CardTitle className="card-title">
                    Google meet
                  </CardTitle>
                </CardBody>
              </Card>
            </a>
          </Col>
          */}
        </Row>
        <br />
      </div>
    </>
  );
};

export default Home;