import React, { useEffect, useLayoutEffect } from "react";
import { DropDownNav } from "../MyComponents/MyNavComponents";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { Translate } from "../../helpers/Translate";
import { transcode } from "buffer";
import { useSelector } from "react-redux";
// function getMenu(props:MenuItem){
//   let t = new Translate();
//     const subMenu = props.MenuItems.map((m:MenuItem) => {
//             return(
//                 getMenu(m)
//             );
//     });
//     const pages = props.Pages.map((p:Page) => {
//       return(
//       <NavLink key={p.PageId} className="nav-link" to={"/dashboard/"+p.Name.replace(" ","")}>{t.translate(p.Name)}</NavLink>
//       );
//     });

//   return(
//     <DropDownNav key={props.MenuItemId} name={props.Name} className="fa fa-lock">
//       {subMenu}
//       {pages}
//     </DropDownNav>
//   );
// }
interface MenuItem {
  MenuItemId: number;
  Name: string;
  MenuItems: Array<MenuItem>;
  Pages: Array<Page>;
}
interface Page {
  PageId: number;
  Name: string;
}

export default function SideNav(props: any) {
  const transjwt: any = useSelector((state: any) => state.Parent.transjwt);

  let t = new Translate();
  // const subMenu = props.Menu.MenuItems.map((m:MenuItem) => {
  //         return(
  //             getMenu(m)
  //         );
  // });
  {
    /*<NavLink  className="nav-link" to={"/dashboard/users"} key ="1"> Users</NavLink>,*/
  }
  const pages = [
    <NavLink key="2" className="nav-link" to={"/dashboard/dopayment"}>
      Do Payments
    </NavLink>,
    <NavLink key="3" className="nav-link" to={"/dashboard/payments"}>
      Payments
    </NavLink>,
    <NavLink key="4" className="nav-link" to={"/dashboard/subscriptions"}>
      Subscriptions{" "}
    </NavLink>,
  ];

  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark navbar-header"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <Nav className="nav">
            <NavLink
              key="10"
              className="nav-link fa fa-home"
              to={"/dashboard/home"}
            >
              {/* &nbsp; Home{" "} */}
              &nbsp;
              {t.translate("Home")}
            </NavLink>

            {/* <DropDownNav key="1" name="Payments" className="fa fa-credit-card">


              {pages}
            </DropDownNav>*/}

            <NavLink
              key="8"
              className="nav-link fa fa-money"
              to={"/dashboard/ewallet"}
            >
              {/* &nbsp; Smart E-Wallet{" "} */}
              &nbsp; {" "}
              {t.translate("Smart E-Wallet")}

            </NavLink>
            <NavLink
              key="7"
              className="nav-link fa fa-users"
              to={"/dashboard/viewstudents"}
            >
              {/* &nbsp; Students{" "} */}
              &nbsp; {" "}
              {t.translate("Students")}

            </NavLink>
            {/* <NavLink
              key="11"
              className="nav-link fa fa-money"
              to={"/dashboard/installments"}
            >
              &nbsp; Installments{" "}
            </NavLink> */}
           
          
            <NavLink
              key="19"
              className="nav-link fas fa-clock-o"
              to={"/dashboard/latearrival"}
            >
              &nbsp; {t.translate("Late Arrival")}
            </NavLink>
            <NavLink
              key="19"
              className="nav-link fas fa-clock-o"
              to={"/dashboard/earlydismissal"}
            >
              &nbsp; {t.translate("Early Dismissal")}
            </NavLink>
            <NavLink
              key="70"
              className="nav-link fa fa-bed"
              to={"/dashboard/excuses"}
            >
              &nbsp; {t.translate("Excuses")}
            </NavLink>
           
            <NavLink
              key="20"
              className="nav-link fa fa-comments"
              to={"/dashboard/meetings"}
            >
              &nbsp; {t.translate("Request Meeting")}
            </NavLink>
            {/* <NavLink
              key="6"
              className="nav-link fa fa-comments"
              to={"/dashboard/chatlist"}
            >
              &nbsp; {t.translate("Messages")}
            </NavLink> */}
            <NavLink
              key="14"
              className="nav-link fas fa-book-open"
              to={"/dashboard/academicinterventions"}
            >
              &nbsp; {t.translate("Academic interventions")}
            </NavLink>
            <NavLink
              key="15"
              className="nav-link fas fa-ban"
              to={"/dashboard/interventions"}
            >
              &nbsp; {t.translate("Character Interventions")}
            </NavLink>
            <NavLink
              key="16"
              className="nav-link fas fa-stethoscope"
              to={"/dashboard/clinicvisits"}
            >
              &nbsp; {t.translate("Clinic Visits")}
            </NavLink>
           
            <NavLink
              key="30"
              className="nav-link fas fa-heart text-danger"
              to={"/dashboard/WallOfLove"}
            >
              <span className="text-white"> &nbsp; {t.translate("Wall of Love")}</span>
            </NavLink>

            <NavLink
              key="57"
              className="nav-link fa fa-apple"
              to={"/dashboard/cafeteria"}
            >
              {/* &nbsp; Cafeteria{" "} */}
              &nbsp; {t.translate("Cafeteria")}
            </NavLink>
            <a
              key="4"
              target="blank"
              className="nav-link fa fa-bus"
              href={`https://transportation.ivyis.org/?hash=` + transjwt}
            >
              &nbsp; {t.translate("Transportation")}
            </a>

            {/*             <div className="sb-sidenav-menu-heading">Addons</div>
            <NavLink className="nav-link" to="charts.html">
              <div className="sb-nav-link-icon"><i className="fa fa-area-chart"></i></div>
            Charts
          </NavLink>
            <NavLink className="nav-link" to="tables.html">
              <div className="sb-nav-link-icon"><i className="fa fa-table"></i></div>
            Tables
            </NavLink> */}
          </Nav>
        </div>
      </nav>
    </div>
  );
}
